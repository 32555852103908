import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HISTORY,
  EDIT_COIN,
  GET_USER_BY_STATUS,
  SET_USER_STATUS,
  GET_HOST,
} from "./types";
import { baseURL, key } from "../../util/Config";

// get users
export const getUser =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { key: key },
    };
    console.log("start------------------------",start);
    console.log("limitssssssssssssssssss------22",limit);
    console.log("search value------------",searchValue);
    console.log("start datee------------------",sDate);
    console.log("end dte-------------------------",eDate);
    fetch(
      `${baseURL}getUsers?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          let male, female;
          if (res.maleFemale) {
            // eslint-disable-next-line
            res.maleFemale.map((data) => {
              if (data._id === "Female") return (female = data.gender);
              if (data._id === "Male") return (male = data.gender);
            });
          }
          let approvedUser = [];
          let pendingUser = [];
          let blockedUser = [];
          res?.user?.forEach((data) => {
            if (data?.hostRequest?.status === "approved") {
              approvedUser.push(data);
            }
            if (data?.hostRequest?.status === "pending") {
              pendingUser.push(data);
            }
            if (data?.hostRequest?.status === "rejected") {
              blockedUser.push(data);
            }
          });

          dispatch({
            type: GET_USER,
            payload: {
              user: res.user,
              activeUser: approvedUser,
              pendingUser,
              blockedUser,
              total: res.total,
              male: male,
              female: female,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };
export const getHosts =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { key: key },
    };
    fetch(
      `${baseURL}getHosts?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          let male, female;
          if (res.maleFemale) {
            // eslint-disable-next-line
            res.maleFemale.map((data) => {
              if (data._id === "Female") return (female = data.gender);
              if (data._id === "Male") return (male = data.gender);
            });
          }
          let approvedUser = [];
          let pendingUser = [];
          let blockedUser = [];
          res?.user?.forEach((data) => {
            if (data?.hostRequest?.status === "approved") {
              approvedUser.push(data);
            }
            if (data?.hostRequest?.status === "pending") {
              pendingUser.push(data);
            }
            if (data?.hostRequest?.status === "rejected") {
              blockedUser.push(data);
            }
          });

          dispatch({
            type: GET_HOST,
            payload: {
              user: res.user,
              activeUser: approvedUser,
              pendingUser,
              blockedUser,
              total: res.total,
              male: male,
              female: female,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const getUserByStatus = (status) => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: { key: key },
  };
  fetch(`${baseURL}getUsersByStatus?status=${status}`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_USER_BY_STATUS,
        payload: {
          userDataByStatus: res.userDataByStatus,
        },
      });
    })
    .catch((error) => Toast("error", error.message));
};

export const setUserStatus = (data) => (dispatch) => {
  axios
    .post(`${baseURL}setUserStatus`, data)
    .then((res) => {
      dispatch({
        type: SET_USER_STATUS,
        payload: {
          data,
        },
      });
      Toast("success", res.message);
    })
    .catch((error) => Toast("error", error.message));
};

export const handleBlockUnblockSwitch = (userId) => (dispatch) => {
  console.log("🚀 ~ handleBlockUnblockSwitch ~ userId:", userId);
  axios
    .patch(`blockUnblock/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BLOCK_UNBLOCK_SWITCH, payload: res.data.user });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("🚀 ~ handleBlockUnblockSwitch ~ error:", error);
      return Toast("error", error);
    });
};

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
