import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { register } from "../../store/agency/action";
import { getAllSubAdminsUniqueIds } from "../../store/sub-admin/action";
import { masterAgencyRegister } from '../../store/masterAgency/action';
import { signupAdmin } from '../../store/admin/action';
// import { WebAppName } from "../../util/Config";

const MasterSignUpPage = (props) => {
  // const location = useLocation();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [error, setError] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPass: "",
    contactNo: "",
    country: "",
    city: "",
  });


  const superAdmin = JSON.parse(localStorage.getItem("superAdmin"));

  const initialStateToEmpty = () => {
    setUserName("");
    setEmail("");
    setCountry("");
    setCity("");
    setPassword("");
    setContactNo("");
    setConfirmPass("");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // Check for any empty fields
    if (!email || !password || !userName || !contactNo || !country || !city || !confirmPass) {
      const newErrors = {};
      if (!userName) newErrors.userName = "Name is Required!";
      if (!email) newErrors.email = "Email is Required!";
      if (!password) newErrors.password = "Password is Required!";
      if (!contactNo) newErrors.contactNo = "contact No is Required!";
      if (!country) newErrors.country = "Country is Required!";
      if (!city) newErrors.city = "City is Required!";
      if (!confirmPass) newErrors.confirmPass = "ConfirmPass is required";

      setError({ ...error, ...newErrors });
      return; // Prevent submission when fields are empty
    }
    // Check if password and confirm password match
    if (password !== confirmPass) {
      setError({ ...error, confirmPass: "Passwords don't match." });
      return;
    }
    // Clear any previous errors if everything is filled
    setError({ userName: "", email: "", password: "", contactNo: "", country: "", city: "", confirmPass: "" });
    // Prepare data for registration
    const data = {
      name: userName,
      email,
      password,
      contactNo,
      country,
      city,
      superAdminId: superAdmin.payload._id
    };

    props.signupAdmin({
      signup: data,
      onSuccess: () => initialStateToEmpty(),
    });

  };

  return (
    <>
      <div
      // className="login-page back__style"
      >
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 col-lg-5">
              <div className="card login-box-container">
                <div className="card-body">
                  {/* <div className="authent-logo mb-4">
                    <span className="text-danger h1">{WebAppName}</span>
                  </div> */}
                  <div className="authent-text">
                    <p>Create a Master Admin</p>
                  </div>

                  <form autoComplete="off">
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@123"
                          required
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Master Admin Name</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.userName && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.userName}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Email address</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.email && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.email}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Bangladesh/India/Pakistan"
                          required
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Country</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.country && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.country}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Dhaka/Mumbai/Lahore"
                          required
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <label htmlFor="floatingInput">City</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.city && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.city}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control"
                          id="phoneNo"
                          placeholder="phoneNo"
                          value={contactNo}
                          onChange={(e) => setContactNo(e.target.value)}
                        />
                        <label htmlFor="phoneNo">PhoneNo</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.contactNo && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red">
                              {error.contactNo}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.password && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red">
                              {error.password}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="confirm Password"
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Confirm Password</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.confirmPass && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red">
                              {error.confirmPass}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-danger m-b-xs"
                        onClick={handleSubmit}
                      >
                        Create
                      </button>
                    </div>
                  </form>
                  {/* <div className="flex justify-between w-full">
                    Already have an account?
                    <Link to="/" className="text-info">
                      Sign In
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  signupAdmin,
  // getAllSubAdminsUniqueIds,
  // masterAgencyRegister,
})(MasterSignUpPage);
