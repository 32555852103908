import React, { useEffect, useState } from "react";

//redux
import { connect, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//action
import { getProfile } from "../../store/superAdmin/action";
import { useRouteMatch } from "react-router-dom";
//pagination
import Pagination from "../../pages/Pagination";

//routing
import { Link } from "react-router-dom";

import DiamondHistoryTable from "../Master/DiamondHistoryTable";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";

const SuperAdminDiamondHistory = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const [date, setDate] = useState([]);

  const match = useRouteMatch();
  const status = match.params.status;

  // const superAdminLocal = JSON.parse(localStorage.getItem("superAdmin"));
  useEffect(() => {
    props.getProfile(
      // superAdminLocal.payload._id,

      activePage,
      rowsPerPage,
      search,
      sDate,
      eDate
    );
  }, [status, activePage, rowsPerPage, search, sDate, eDate]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
  }, [date]);

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };
  const getAllDiamondHistoryForSuperAdmin = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker");
    getProfile(activePage, rowsPerPage, sDate, eDate);
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const { superAdmin, total } = useSelector((state) => state.superAdmin);
  console.log("🚀 ~ SuperAdminDiamondHistory ~ total:", total);
  console.log("superAdmin,,,,,,,,,,,,,,,,,,", superAdmin);
  const diamondStatusHistory = superAdmin?.diamondsHistory?.filter(
    (history) => history.status === status
  );
  console.log(
    "🚀 ~ SuperAdminDiamondHistory ~ diamondStatusHistory:",
    diamondStatusHistory
  );

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white ">{`${
              status.charAt(0).toUpperCase() + status.slice(1)
            } Diamond History`}</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/superAdmin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Diamond
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllDiamondHistoryForSuperAdmin}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0"></div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("YYYY/MM/DD");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "YYYY/MM/DD"
                          );
                          setActivePage(1);
                          setsDate(dayStart);

                          seteDate(dayEnd);
                          getProfile(
                            activePage,
                            rowsPerPage,
                            search,
                            sDate,
                            eDate
                          );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <DiamondHistoryTable
                data={diamondStatusHistory}
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                status={"sent"}
              />
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getProfile })(SuperAdminDiamondHistory);
