import React, { useEffect } from "react";

// routing
import { NavLink as Link } from "react-router-dom";

// alert
import { warning } from "../../util/Alert";

// redux
import { useDispatch, useSelector } from "react-redux";

// types
import { UNSET_ADMIN } from "../../store/admin/types";

//MUI
import { makeStyles } from "@material-ui/core";

// jquery
import $ from "jquery";
import { WebAppTitle } from "../../util/Config";
import { getPermissionsForMaster } from "../../store/permission/action";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      color: "#E85382 !important",
      fontWeight: 500,
      fontSize: 16,
    },
    "&.active span": {
      color: "#E85382 !important",
      fontWeight: 900,
    },
  },
  hidden: {
    display: "none",
  },
}));

const Navbar = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.admin);
  const id = admin._id;
  const role = admin.role || "Admin";
  useEffect(() => {
    dispatch(getPermissionsForMaster(role, id));
  }, [dispatch, id, role]);
  const { adminPermission, loading } = useSelector((state) => state.permission);
  useEffect(() => {
    console.log("Admin Permissions in Component:", adminPermission);
  }, [adminPermission]);
  const hasPermission = (permissionName) => {
    return adminPermission.some(
      (permission) => permission.resource === permissionName
    );
  };
  const hasPermissionWithOptions = (resource, option) => {
    return adminPermission.some(
      (permission) =>
        permission.resource === resource &&
        permission.options &&
        permission.options[option]
    );
  };

  // console.log("🚀 ~ permissions:", permissions);
  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_ADMIN });
        window.location.href = "/admin/login";
      }
    });
  };

  useEffect(() => {
    $("").addClass("submenu-margin");
  }, []);
  return (
    <>
      <div class="page-sidebar">
        <Link to="/admin/dashboard">
          <span className="logo text-danger">{WebAppTitle}</span>
        </Link>
        <ul class="list-unstyled accordion-menu">
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Dashboard"
          >
            <Link to="/admin/dashboard" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="activity"></i>
              </span>
              Dashboard
            </Link>
          </li>

          {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="User">
            <Link to="/admin/user" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              User
            </Link>
          </li> */}

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Admin Management"
            className="pointer-cursor"
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              Management
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li>
                <Link to="/admin/coins" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Coin
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/agency/create"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Agency
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Agency"
            className={` cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              Admin Data
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li className={` cursor-pointer`}>
                <Link to="/admin/agency" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Hosting/TopUp
                </Link>
              </li>
              <li className={` cursor-pointer`}>
                <Link to="/admin/masterAgency" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Master TopUp
                </Link>
              </li>
              {/* <li className={` cursor-pointer`}>
              <Link to="/admin/sub-admin" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Trial-Admins
                </Link>
              </li> */}
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Purchase Plan History"
            className={` cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="clock"></i>
              </span>
              Diam History
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li>
                <Link to="/admin/history/sent" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Sent
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/history/received"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Recieved
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/admin/vipPlan/history"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>VIP Plan
                </Link>
              </li> */}
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="User"
            className={`${
              hasPermission("Users") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              User List
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("Users", "real")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/user" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Real
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Users", "fake")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/fakeUser" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Fake
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Users", "host")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/host" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Host
                </Link>
              </li>
            </ul>
          </li>

          {/* <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Top-Seller"
          >
            <Link to="/admin/top-seller" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              Top-Seller
            </Link>
          </li> */}
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Agency"
            className={`${
              hasPermission("Admins") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              Admin List
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("Admins", "Hosting/TopUp")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/allagency" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Hosting/TopUp
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Admins", "masterAgency")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/allmasterAgency"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Master TopUp-Agency
                </Link>
              </li>
              {/* <li
                className={`${
                  hasPermissionWithOptions("Admins", "TrialAdmins")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
            <Link to="/admin/sub-admin" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Trial Admins
                </Link>
              </li> */}
            </ul>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Banner"
            className={`${
              hasPermission("Banner") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/banner" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="image"></i>
              </span>
              Banner
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Plan"
            className={`${
              hasPermission("Plan") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="layout"></i>
              </span>
              Plan
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("Plan", "coinPlan")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/coinPlan" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Coin Plan
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Plan", "vipPlan")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/vipPlan" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>VIP Plan
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Purchase Plan History"
            className={`${
              hasPermission("PlanHistory") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="clock"></i>
              </span>
              Plan History
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("PlanHistory", "coinPlan")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/coinPlan/history"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Coin Plan
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("PlanHistory", "vipPlan")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/vipPlan/history"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>VIP Plan
                </Link>
              </li>
            </ul>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Gift"
            className={`${
              hasPermission("Gifts") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="gift"></i>
              </span>
              Gift
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("Gifts", "category")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/giftCategory"
                  className={`${classes.navLink}`}
                  onClick={() => localStorage.removeItem("GiftClick")}
                >
                  <i class="far fa-circle"></i>Category
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Gifts", "gift")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/gift"
                  className={`${classes.navLink}`}
                  onClick={() => localStorage.setItem("GiftClick", true)}
                >
                  <i class="far fa-circle"></i>Gift
                </Link>
              </li>
            </ul>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Sticker"
            className={`${
              hasPermission("Sticker") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/sticker" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="anchor"></i>
              </span>
              Sticker
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Theme"
            className={`${
              hasPermission("Theme") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/theme" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="image"></i>
              </span>
              Theme
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Song"
            className={`${
              hasPermission("Song") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/song" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="music"></i>
              </span>
              Song
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Hashtag"
            className={`${
              hasPermission("HashTag") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/hashtag" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="hash"></i>
              </span>
              Hashtag
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Dashboard"
            className={`${
              hasPermission("FakeComment") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/comment" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="message-circle"></i>
              </span>
              Fake Comment
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Level"
            className={`${
              hasPermission("Level") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/level" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="bar-chart"></i>
              </span>
              Level
            </Link>
          </li>
          {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Post">
          {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Post">
            <Link to="/admin/post" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="maximize"></i>
              </span>
              Post
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Video">
            <Link to="/admin/video" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="film"></i>
              </span>
              Video
            </Link>
          </li> */}

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Post"
            className={`${
              hasPermission("post") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="maximize"></i>
              </span>
              Post
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("post", "real") ? "" : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/post" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Real
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("post", "fake") ? "" : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/post/fake" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Fake
                </Link>
              </li>
            </ul>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Video"
            className={`${
              hasPermission("video") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="film"></i>
              </span>
              Video
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("video", "real")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/video" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Real
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("video", "fake")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link to="/admin/video/fake" className={`${classes.navLink}`}>
                  <i class="far fa-circle"></i>Fake
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Video"
            className={`${
              hasPermission("ReportedUser") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/reportedUser" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="flag"></i>
              </span>
              Reported User
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Complain"
            className={`${
              hasPermission("Complain") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="help-circle"></i>
              </span>
              Complain
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("Complain", "pending")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/pendingComplain"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Pending
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Complain", "solved")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/solvedComplain"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Solved
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Redeem"
            className={`${
              hasPermission("Redeem") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <a
              href={() => false}
              className="add-collapse-margin"
              style={{ marginLeft: 0 }}
            >
              <span className="sidenav__icon">
                <i data-feather="key"></i>
              </span>
              Redeem
              <i class="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul class="">
              <li
                className={`${
                  hasPermissionWithOptions("Redeem", "pending")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/pendingRedeem"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Pending
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Redeem", "accepted")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/AcceptedRedeem"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Accepted
                </Link>
              </li>
              <li
                className={`${
                  hasPermissionWithOptions("Redeem", "declined")
                    ? ""
                    : classes.hidden
                } cursor-pointer`}
              >
                <Link
                  to="/admin/declineRedeem"
                  className={`${classes.navLink}`}
                >
                  <i class="far fa-circle"></i>Declined
                </Link>
              </li>
            </ul>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Google Ad"
            className={`${
              hasPermission("GoogleAd") ? "" : classes.hidden
            } cursor-pointer`}
          >
            <Link to="/admin/advertisement" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="book"></i>
              </span>
              Google Ad
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Profile">
            <Link to="/admin/profile" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="user"></i>
              </span>
              Profile
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
            <a
              href={() => false}
              onClick={handleLogout}
              className="add-collapse-margin"
            >
              <i data-feather="log-out"></i>Logout
            </a>
          </li>
        </ul>
        <a
          href={() => false}
          id="sidebar-collapsed-toggle"
          style={{ opacity: 0, pointerEvents: "none" }}
        >
          <i data-feather="arrow-right"></i>
        </a>
      </div>
    </>
  );
};
export default Navbar;
