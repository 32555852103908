import React, { useEffect, useState } from "react";

// antd
import { Popconfirm } from "antd";

//react-router
import { connect, useSelector } from "react-redux";

import $ from "jquery";
import { Link, useHistory, useLocation } from "react-router-dom";
import male from "../../assets/images/male.png";

import { editCoin } from "../../store/user/action";
import { getProfileForSuperAdmin } from '../../store/admin/action';
import { getAllTopUpHostingAgenciesForMaster, getAllMasterTopUpAgenciesForMaster } from '../../store/admin/action';
import TopUpHostTable from './TopUpHostTable';
import MasterTopUpTableMaster from './MasterTopUpAgencyForMaster'
//serverPath
import { baseURL } from "../../util/Config";

// dayjs
import dayjs from "dayjs";

//image
import Male from "../../assets/images/male.png";

//inline edit
import EdiText from "react-editext";

//toast
import { Toast } from "../../util/Toast";

const UserDetail = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = queryParams.get('id');

  useEffect(() => {
    props.getProfileForSuperAdmin(params);
    props.getAllTopUpHostingAgenciesForMaster(params);

  }, [])

  const { adminProfile } = useSelector((state) => state.admin);

  const post = useSelector((state) => state.post.post);
  const video = useSelector((state) => state.video.video);
  const followersFollowingList = useSelector(
    (state) => state.followersFollowing.followersFollowing
  );

  const comment = useSelector((state) => state.post.comment);
  const like = useSelector((state) => state.post.like);



  // eslint-disable-next-line
  const [showAgency, setShowAgency] = useState(true);
  const [followersFollowing, setFollowersFollowing] = useState(false);
  const [post_, setPost] = useState("post");
  const [postVideo, setPostVideo] = useState([]);

  const [coin, setCoin] = useState(0);
  const [isCoin, setIsCoin] = useState(false);

  const [diamond, setDiamond] = useState(0);
  const [isDiamond, setIsDiamond] = useState(false);

  $(document).ready(() => {
    $("#manageAgencyHosts").on("click", "a", function () {
      // remove className 'active' from all li who already has className 'active'
      $("#manageAgencyHosts a.active").removeClass("active");
      // adding className 'active' to current click li
      $(this).addClass("active");
      if ($(this).attr("at") === "Videos") {
        setShowAgency(false);
        setFollowersFollowing(false);
      } else {
        if ($(this).attr("at") === "Agency") {
          setShowAgency(true);
          setFollowersFollowing(false);
        } else setFollowersFollowing(true);
      }
    });
  });
  // // set default Image

  // $(document).ready(function () {
  //   $("img").bind("error", function () {
  //     // Set the default image
  //     $(this).attr("src", `${baseURL}storage/male.png`);
  //   });
  // });

  document.addEventListener(
    "play",
    function (e) {
      var videos = document.getElementsByTagName("video");
      for (var i = 0, len = videos.length; i < len; i++) {
        // eslint-disable-next-line
        if (videos[i] != e.target) {
          videos[i].pause();
        }
      }
    },
    true
  );

  useEffect(() => {
    if (!params) {
      setPostVideo(post);
    }
  }, [post]);

  useEffect(() => {
    if (!params) {
      setPostVideo(video);
    }
  }, [video]);

  useEffect(() => {
    $("#manageAgencyHosts li a").first().addClass("active");
  }, []);

  let now = dayjs();
  const [activeTab, setActiveTab] = useState("Agency"); // Add activeTab state

  const { masterAgenciesList, masterAgenciesTotal } = useSelector((state) => state?.admin);

  const { agenciesList, total } = useSelector((state) => state?.admin);

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "MasterTopUp") {
      props.getAllMasterTopUpAgenciesForMaster(params);
    }
    else {
      if (tab === "Agency") {
      }
      else
        setFollowersFollowing(true);
    }
  };


  const handleSave = (coin, id, type) => {

    const validNumber = /^\d+$/.test(coin);
    if (!validNumber) {
      return Toast("error", "Invalid Value");
    }
    let data;
    if (type === "rCoin") {
      setIsCoin(true);
      setCoin(coin);
      data = {
        userId: id,
        rCoin: coin,
      };
    } else {
      setIsDiamond(true);
      setDiamond(coin);
      data = {
        userId: id,
        diamond: coin,
      };
    }
    props.editCoin(data);
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last"></div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/superAdmin/masterAdmin" className="text-danger">
                    Master
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="profile-cover"></div>
          <div class="profile-header ">
            <div class="profile-img">
              <img src={adminProfile?.image ? `${baseURL}${adminProfile?.image}` : Male} alt="" />
            </div>
            <div class="profile-name">
              <h3>{adminProfile?.name}</h3>
            </div>
            <div class="profile-header-menu">
              <ul className="list-unstyled" id="manageAgencyHosts">
                <li>
                  <a
                    href={() => false}
                    className={`pointer-cursor ${activeTab === "Agency" ? "active" : ""}`}
                    onClick={() => handleTabClick("Agency")}
                  >
                    Agency ({adminProfile?.agencies?.length || 0})
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className={`pointer-cursor ${activeTab === "MasterTopUp" ? "active" : ""}`}
                    onClick={() => handleTabClick("MasterTopUp")}
                  >
                    Master TopUp agency ({adminProfile?.masterAgencies?.length || 0})
                  </a>
                </li>
                {/* <li>
                  <a
                    href={() => false}
                    className={`pointer-cursor ${activeTab === "Hosts" ? "active" : ""}`}
                    onClick={() => handleTabClick("Hosts")}
                  >
                    Hosts ({adminProfile?.video || 0})
                  </a>
                </li> */}

                <li>
                  <a
                    href={() => false}
                    className={`pointer-cursor ${activeTab === "TrialAdmin" ? "active" : ""}`}
                    onClick={() => handleTabClick("TrialAdmin")}
                  >
                    Trial admin ({adminProfile?.trialAdmin?.length || 0})
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        {/* <div class="col-md-12 col-lg-4"> */}
        {/* <div class="card">
            <div class="card-body">
              <h5 class="card-title">About</h5>
              <span className="text-danger">{adminProfile?.bio ? "Bio : " : ""}</span>
              <span>{adminProfile?.bio}</span>
              {adminProfile?.bio && <br />}
              {adminProfile?.bio && <br />}
              <ul class="list-unstyled profile-about-list">
                <li>
                  <i class="fal fa-admin m-r-xxs"></i>
                  <span>
                    Username &nbsp;
                    <span className="text-danger">{adminProfile?.name}</span>
                  </span>
                </li>
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i class="far fa-gem m-r-xxs"></i>
                    Diamond &nbsp;
                    <EdiText
                      type="text"
                      value={isDiamond ? diamond : adminProfile?.diamonds}
                      className="editClass"
                    />
                  </span>
                </li> */}
        {/* <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i class="far fa-registered m-r-xxs "></i>
                    RCoin &nbsp;{" "}
                    <EdiText
                      type="text"
                      value={isCoin ? coin : admin?.rCoin}
                      onSave={(val) => handleSave(val, admin?._id, "rCoin")}
                      className="editClass"
                    />
                  </span>
                </li> */}
        {/* <li>
                  <i class="fas fa-venus-mars m-r-xxs"></i>
                  <span>
                    Gender &nbsp;{" "}
                    <span className="text-danger">{admin?.gender}</span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-child m-r-xxs"></i>
                  <span>
                    Age &nbsp;<span className="text-danger">{admin?.age}</span>
                  </span>
                </li> */}

        {/* <li>
                  <i class="fas fa-sign-in-alt m-r-xxs"></i>
                  <span>
                    Login Type &nbsp;
                    <span className="text-danger">
                      {admin?.loginType === 0
                        ? "Google"
                        : admin?.loginType === 1
                          ? "Facebook"
                          : "Quick"}
                    </span>
                  </span>
                </li> */}
        {/* <li>
                  <i class="fas fa-crown m-r-xxs"></i>
                  <span>
                    isVIP &nbsp;
                    <span
                      className={`${admin?.isVIP ? "text-success" : "text-primary"
                        }`}
                    >
                      {admin?.isVIP ? "Yes" : "No"}
                    </span>
                  </span>
                </li> */}
        {/* <li>
                  <i class="fas fa-map-marker m-r-xxs"></i>
                  <span>
                    Ip &nbsp;<span className="text-danger">{admin?.ip}</span>
                  </span>
                </li>
                <li>
                  <i class="fas fa-clock m-r-xxs"></i>
                  <span>
                    Last login date &nbsp;
                    <span className="text-danger">{admin?.lastLogin}</span>
                  </span>
                </li> */}
        {/* <li class="profile-about-list-buttons">
                  <button class="btn btn-block btn-primary m-t-md icon_margin">Follow</button>
                  <button class="btn btn-block btn-secondary m-t-md icon_margin">Message</button>
                </li> */}
        {/* </ul>
            </div>
          </div> */}
        {/* <div class="card">
            <div class="card-body">
              <h5 class="card-title">Contact Info</h5>
              <ul class="list-unstyled profile-about-list">
                <li>
                  <i class="far fa-envelope m-r-xxs"></i>
                  <span>{adminProfile?.email}</span>
                </li>
                <li>
                  <i class="far fa-compass m-r-xxs"></i>
                  <span>
                    Lives in <span>{adminProfile?.country}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <div class="col-md-12 col-lg-12">
          <div class="card card-bg">
            <div class="card-body ">
              {activeTab === "Agency" ? (
                <div
                  class="container followDiv"
                  style={{ maxHeight: 548, overflow: "auto" }}
                >
                  <div class="row post-comments d-flex flex-wrap justify-content-between">
                    {agenciesList?.length > 0 ? (
                      <TopUpHostTable agency={agenciesList} totalAgency={total} />
                    ) : (
                      <p className="text-center">Nothing to Show!!</p>
                    )}
                  </div>
                </div>
              ) : activeTab === "MasterTopUp" ? (
                <MasterTopUpTableMaster masterAgency={masterAgenciesList} total={masterAgenciesTotal} />
              ) : activeTab === "TrialAdmin" ? (
                // Render Trial Admin content
                <div>Trial Admin content here</div>
              ) : (
                // Render other tab content
                <p className="text-center">Nothing to Show!!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  post: state.post.post,
  video: state.video.video,
  followersFollowing: state.followersFollowing.followersFollowing,
});

export default connect(mapStateToProps, {
  editCoin,
  getProfileForSuperAdmin,
  getAllTopUpHostingAgenciesForMaster,
  getAllMasterTopUpAgenciesForMaster
})(UserDetail);

