import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  SET_SUPER_ADMIN,
  SIGNUP_SUPER_ADMIN,
  UNSET_SUPER_ADMIN,
  UPDATE_PROFILE,
} from "./types";
import { WebAppTitle } from "../../util/Config";

// export const signupAdmin = (signup) => (dispatch) => {
//   axios
//     .post("/superAdmin/signup", signup)
//     .then((res) => {
//       console.log(res);
//       if (res.data.status) {
//         dispatch({ type: SIGNUP_SUPER_ADMIN });
//         Toast("success", "Signup Successfully!");
//         setTimeout(() => {
//           window.location.href = "/login";
//         }, 3000);
//       } else {
//         Toast("error", res.data.message);
//       }
//     })
//     .catch((error) => {
//       Toast("error", error);
//       console.log(error);
//     });
// };

export const login = (data) => (dispatch) => {
  axios
    .post("/super-admin/login", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", `You have successfully logged in ${WebAppTitle}.`);
        window.location.href = "/superAdmin/dashboard";
        dispatch({ type: SET_SUPER_ADMIN, payload: res.data.token });
        localStorage.setItem("superAdmin", JSON.stringify(res.data));
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {});
};

export const sendEmail = (data) => (dispatch) => {
  axios
    .post("admin/sendEmail", data)
    .then((res) => {
      if (res.data.status) {
        Toast(
          "success",
          "Mail has been sent successfully. Sometimes mail has been landed on your spam!"
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getProfile =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("start------------------------lino 355", status);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    let authToken = localStorage.getItem("TOKEN");
    axios
      .get(
        `/super-admin/profile?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.superAdmin); //lino no 74
        if (res.data.status) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: {
              superAdmin: res.data.superAdmin,
              total: res.data.superAdmin.totalDiamondsHistoryRecords, //change to total
            },
          });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };
console.log("🚀 ~ status:", status);

export const changePassword = (data) => (dispatch) => {
  axios
    .put("/superAdmin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_SUPER_ADMIN });
          window.location.href = "/";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("/superAdmin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({
          type: UPDATE_PROFILE,
          payload: { superAdmin: data.superAdmin },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateCode = (signup) => (dispatch) => {
  axios
    .patch("admin/updateCode", signup)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        console.log("res.data", res.data);
        Toast("success", "Purchase Code Update Successfully !");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};

export const sendDiamondsFromSuperAdmin = (bodydata) => async (dispatch) => {
  console.log("data send tranfer", bodydata);
  try {
    const { data } = await axios.post(`/super-admin/transfer/coins`, bodydata);
    console.log(
      "🚀 ~ sendDiamondsFromSuperAdmin ~ data:",
      data.superAdmin.diamonds
    );

    if (data.success) {
      Toast("success", data.message);
      dispatch({
        type: UPDATE_PROFILE,
        payload: { superAdmin: data.superAdmin },
      });
    } else {
      Toast("error", data.message);
    }
  } catch (error) {
    console.log("error", error.response);
    Toast("error", error.response.data.error);
  }
};

export const generateCoinsSuperAdmin = (bodydata) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/super-admin/generate/coins`, bodydata);
    Toast("success", data.message);
    dispatch({
      type: UPDATE_PROFILE,
      payload: { superAdmin: data.superAdmin },
    });
  } catch (error) {
    console.log("error", error.response);
    Toast("error", error.response.data.message || error.response.data.error);
  }
};
