import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getPermissionsForMaster,
  assignUpdatePermissions,
} from "../../store/permission/action";

const Permission = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsId = queryParams.get("id");
  const paramsRole = queryParams.get("role");

  useEffect(() => {
    dispatch(getPermissionsForMaster(paramsRole, paramsId));
  }, [dispatch, paramsId, paramsRole]);

  const { adminPermission, loading } = useSelector((state) => state.permission);

  const [permissionsList, setPermissionsList] = useState([]);
  const [selectedResource, setSelectedResource] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!loading && adminPermission) {
      setPermissionsList(adminPermission);
    }
  }, [loading, adminPermission]);

  const resources = {
    Users: ["real", "fake", "host"],
    Admins: ["Hosting/TopUp", "masterAgency", "TrialAdmins"],
    Banner: [],
    Plan: ["coinPlan", "vipPlan"],
    PlanHistory: ["coinPlan", "vipPlan"],
    Gifts: ["gift", "category"],
    Sticker: [],
    Theme: [],
    Song: [],
    HashTag: [],
    FakeComment: [],
    Level: [],
    ReportedUser: [],
    GoogleAd: [],
    Redeem: ["pending", "accepted", "declined"],
    Complain: ["pending", "solved"],
    video: ["real", "fake"],
    post: ["real", "fake"],
  };

  const handleResourceChange = (e) => {
    setSelectedResource(e.target.value);
    setSelectedOptions({});
  };

  const handleOptionChange = (e) => {
    const { name, checked } = e.target;
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  const handleAddPermission = () => {
    const newPermission = {
      resource: selectedResource,
      options: selectedOptions,
      isEdit,
    };
    setPermissionsList([...permissionsList, newPermission]);
    setSelectedResource("");
    setSelectedOptions({});
    setIsEdit(false);
  };

  const handleRemovePermission = (index) => {
    const updatedPermissions = [...permissionsList];
    updatedPermissions.splice(index, 1);
    setPermissionsList(updatedPermissions);
  };

  const handleSubmit = () => {
    const data = {
      Id: paramsId,
      role: paramsRole,
      permissionsList,
    };
    console.log("🚀 ~ handleSubmit ~ data:", data);

    props.assignUpdatePermissions(data);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h3 className="text-white">Add Permission</h3>
          <select
            className="form-select mb-3"
            value={selectedResource}
            onChange={handleResourceChange}
          >
            <option value="">Select Resource</option>
            {Object.keys(resources).map((resource) => (
              <option key={resource} value={resource}>
                {resource}
              </option>
            ))}
          </select>
          {selectedResource && (
            <>
              <h4 className="text-white">Options</h4>
              <div className="mb-3">
                <label className="form-check-label text-white">
                  Is Editable:
                  <input
                    style={{ border: "2px solid white", marginLeft: "10px" }}
                    type="checkbox"
                    className="form-check-input"
                    checked={isEdit}
                    onChange={() => setIsEdit(!isEdit)}
                  />
                </label>
              </div>
              {resources[selectedResource].map((option) => (
                <div key={option} className="mb-3">
                  <label className="form-check-label text-white">
                    {option}:
                    <input
                      type="checkbox"
                      style={{ border: "2px solid white", marginLeft: "10px" }}
                      name={option}
                      className="form-check-input"
                      checked={selectedOptions[option] || false}
                      onChange={handleOptionChange}
                    />
                  </label>
                </div>
              ))}
              <button className="btn btn-primary" onClick={handleAddPermission}>
                Add Permission
              </button>
            </>
          )}
        </div>
        <div className="col">
          <h3 className="text-white">Permissions List</h3>
          <ul className="list-group">
            {permissionsList.map((permission, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between align-items-center text-white"
              >
                Resource: {permission.resource} - Options:{" "}
                {JSON.stringify(permission.options)} - Is Editable:{" "}
                {permission.isEdit ? "Yes" : "No"}
                <button
                  className="btn btn-danger"
                  onClick={() => handleRemovePermission(index)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
          <button className="btn btn-success mt-3" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getPermissionsForMaster,
  assignUpdatePermissions,
})(Permission);
