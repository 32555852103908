import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getDiamondHistoryMasterTopUpAgency } from "../../store/masterAgency/action";
import { Link, useHistory, useLocation } from "react-router-dom";
import { baseURL } from "../../util/Config";
import DiamondHistoryTable from "../Master/DiamondHistoryTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../pages/Pagination";
import male from "../../assets/images/male.png"; // Adjust path if necessary
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";

const DiamondHistory = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { masterTopUpAgencyId, masterTopupAgencyName } = params;

  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("ALL");
  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const [statusFilter, setStatusFilter] = useState("sent");
  const [sentPage, setSentPage] = useState(1);
  const [receivedPage, setReceivedPage] = useState(1);
  const [sentRowsPerPage, setSentRowsPerPage] = useState(10);
  const [receivedRowsPerPage, setReceivedRowsPerPage] = useState(10);

  const { masterDiamondHistory, total } = useSelector(
    (state) => state.masterAgency
  );
  console.log(
    "🚀 ~ DiamondHistory ~ masterDiamondHistory:",
    masterDiamondHistory
  );

  useEffect(() => {
    const rowsPerPage =
      statusFilter === "sent" ? sentRowsPerPage : receivedRowsPerPage;
    const currentPage = statusFilter === "sent" ? sentPage : receivedPage;

    props.getDiamondHistoryMasterTopUpAgency(
      statusFilter,
      masterTopUpAgencyId,
      currentPage,
      rowsPerPage,
      search,
      sDate,
      eDate
    );
  }, [
    masterTopUpAgencyId,
    statusFilter,
    sentPage,
    receivedPage,
    sentRowsPerPage,
    receivedRowsPerPage,
    search,
    sDate,
    eDate,
  ]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        { startDate: new Date(), endDate: new Date(), key: "selection" },
      ]);
    }
  }, [date]);

  const diamondHistoryData = masterDiamondHistory?.diamondsHistory || [];
  // const sentCoinsCount = diamondHistoryData.filter(
  //   (record) => record.status === "sent"
  // ).length;
  // const receivedCoinsCount = diamondHistoryData.filter(
  //   (record) => record.status === "received"
  // ).length;

  const filteredDiamondHistoryData = diamondHistoryData.filter(
    (record) => record.status === statusFilter
  );

  const paginatedData = diamondHistoryData.filter(
    (item) => item.status === statusFilter
  );

  const handleRowsPerPage = (value) => {
    if (statusFilter === "sent") {
      setSentRowsPerPage(Number(value));
      setSentPage(1);
    } else {
      setReceivedRowsPerPage(Number(value));
      setReceivedPage(1);
    }
  };

  const handlePageChange = (pageNumber) => {
    if (statusFilter === "sent") {
      setSentPage(pageNumber);
    } else {
      setReceivedPage(pageNumber);
    }
  };

  const getAllDiamondHistory = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    props.getDiamondHistoryMasterTopUpAgency(
      masterTopUpAgencyId,
      activePage,
      statusFilter === "sent" ? sentRowsPerPage : receivedRowsPerPage,
      search,
      sDate,
      eDate
    );
  };

  const handleDateRangeChange = (item) => {
    setDate([item.selection]);
    const dayStart = dayjs(item.selection.startDate).format("YYYY/MM/DD");
    const dayEnd = dayjs(item.selection.endDate).format("YYYY/MM/DD");
    setActivePage(1);
    setsDate(dayStart);
    seteDate(dayEnd);
  };

  const collapsedDatePicker = () => {
    document.getElementById("datePicker").classList.toggle("collapse");
  };

  const agency = masterDiamondHistory?.agency || {};
  const link = location.pathname.startsWith("/admin")
    ? "/admin"
    : "/superAdmin";
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (statusFilter === "sent") {
      setSentPage(1);
    } else {
      setReceivedPage(1);
    }
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Master TopUp Diamond History
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/superAdmin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  masterAgency
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="profile-cover"></div>
          <div className="profile-header">
            <div className="profile-img">
              <img
                src={agency?.image ? `${baseURL}${agency?.image}` : male}
                alt=""
              />
            </div>
            <div className="profile-name">
              <h3>{masterTopupAgencyName}</h3>
            </div>
            <div className="profile-header-menu">
              <ul className="list-unstyled" id="manageAgencyHosts">
                <li>
                  <a
                    href={() => false}
                    className={`pointer-cursor ${
                      statusFilter === "sent" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("sent")}
                  >
                    Send Coins
                     {/* ({sentCoinsCount}) */}
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className={`pointer-cursor ${
                      statusFilter === "received" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("received")}
                  >
                    Received Coins
                     {/* ({receivedCoinsCount}) */}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllDiamondHistory}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && `${sDate} to ${eDate}`}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        // onChange={(e) => {
                        //   setSearch(e.target.value);
                        //   setActivePage(1);
                        // }}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </form>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => handleDateRangeChange(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <DiamondHistoryTable
                data={paginatedData}
                status={statusFilter}
                activePage={statusFilter === "sent" ? sentPage : receivedPage}
                rowsPerPage={
                  statusFilter === "sent"
                    ? sentRowsPerPage
                    : receivedRowsPerPage
                }
              />
              <Pagination
                activePage={statusFilter === "sent" ? sentPage : receivedPage}
                itemsCountPerPage={
                  statusFilter === "sent"
                    ? sentRowsPerPage
                    : receivedRowsPerPage
                }
                userTotal={total}
                onChange={handlePageChange}
                handleRowsPerPage={handleRowsPerPage}
                currentPage={statusFilter === "sent" ? sentPage : receivedPage}
                rowsPerPage={
                  statusFilter === "sent"
                    ? sentRowsPerPage
                    : receivedRowsPerPage
                }
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getDiamondHistoryMasterTopUpAgency,
})(DiamondHistory);
