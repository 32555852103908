import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  GET_FRAME,
  CREATE_NEW_FRAME,
  EDIT_FRAME,
  CLOSE_FRAME_DIALOG,
  DELETE_FRAME,
} from "./types";

export const getFrame = () => (dispatch) => {
  axios
    .get(`frame`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_FRAME, payload: res.data.frame });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const createNewFrame = (data) => (dispatch) => {
  axios
    .post(`/frame`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Frame created successfully!");
        dispatch({ type: CLOSE_FRAME_DIALOG });
        dispatch({ type: CREATE_NEW_FRAME, payload: res.data.frame });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editFrame = (frameId, data) => (dispatch) => {
  axios
    .patch(`frame/${frameId}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Frame updated successfully!");
        dispatch({ type: CLOSE_FRAME_DIALOG });
        dispatch({
          type: EDIT_FRAME,
          payload: { data: res.data.frame, id: frameId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteFrame = (frameId) => (dispatch) => {
  axios
    .delete(`frame/${frameId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_FRAME, payload: frameId });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};