import React from "react";
import Male from "../../../assets/images/male.png";

const TopSellerDiamond = (props) => {
  console.log("props", props.data);
  return (
    <table class="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>Unique ID</th>
          <th>Profile</th>
          <th>Email</th>
          <th>User Name</th>
          <th>Diamond</th>
          <th>Transfer Date</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.length > 0 ? (
          props.data.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.topSeller?.serialId}</td>
                <td>
                  <img
                    height="50px"
                    width="50px"
                    alt="app"
                    src={data.topSeller?.image ? data.topSeller?.image : Male}
                    style={{
                      boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                      border: "2px solid #fff",
                      borderRadius: 10,
                      objectFit: "cover",
                      display: "block",
                    }}
                    className="mx-auto"
                  />
                </td>
                <td>{data.topSeller?.email}</td>
                <td className="text-info">{data.topSeller?.name}</td>
                <td>{data.quantity}</td>
                <td>{data.createdAt}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TopSellerDiamond;
