export const GET_MASTER_AGENCY = "GET_MASTER_AGENCY";
export const SET_MASTER_AGENCY = "SET_MASTER_AGENCY";
export const UNSET_MASTER_AGENCY = "UNSET_MASTER_AGENCY";
export const SEND_EMAIL = "SEND_EMAIL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_MASTER_AGENCY_DETAILS = "GET_MASTER_AGENCY_DETAILS";
export const GET_MASTER_AGENCY_LIST_SUPER_MASTER =
  "GET_MASTER_AGENCY_LIST_SUPER_MASTER";
export const GET_MASTER_TOP_UP_AGENCIES_OF_SUPER_ADMIN =
  "GET_MASTER_TOP_UP_AGENCIES_OF_SUPER_ADMIN";
export const REGISTER_MASTER_AGENCY = "REGISTER_MASTER_AGENCY";
export const REGISTER_MASTER_AGENCY_FAILURE = "REGISTER_MASTER_AGENCY_FAILURE";
export const BLOCK_UNBLOCK_MASTER_AGENCY = "BLOCK_UNBLOCK_MASTER_AGENCY";
export const GET_MASTER_TOP_UP_AGENCIES = "GET_MASTER_TOP_UP_AGENCIES";
export const GET_MASTER_TOP_UP_DIAMOND_HISTORY = "GET_MASTER_TOP_UP_DIAMOND_HISTORY"