import React, { useEffect, useState } from "react";

//jquery
import $ from "jquery";

//redux
import { connect, useSelector } from "react-redux";

//action
// import { getUser, handleBlockUnblockSwitch } from "../../store/user/action";
import { getAllMasterTopUpAgenciesForSuperAdmin, handleBlockUnblock } from "../../store/masterAgency/action";
import { getAllMasterTopUpAgenciesForMaster } from '../../store/admin/action';

//pagination
import Pagination from "../../pages/Pagination";

//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const MasterTopUpAgencyForMaster = (props) => {
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [date, setDate] = useState([]);

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker");
    });
  }, []);

  const { superAdmin } = useSelector((state) => state.superAdmin);
  const { adminProfile } = useSelector((state) => state.admin);

  useEffect(() => {
    setData(props.masterAgency);
  }, [props.masterAgency]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
    setData(props.masterAgency);
  }, [date, props.masterAgency]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  // const handleBlockUnblockSwitch_ = (agencyId) => {
  //   props.handleBlockUnblock(agencyId);
  // };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Master TopUp Agency{" "}
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Serial Id</th>
                    {/* <th>Image</th> */}
                    <th>Username</th>
                    {/* <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      RCoin {coinSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>Phone No</th>
                    <th>Country</th>
                    <th>City</th>
                    <th>isBlock</th>
                    {/* <th>isTopUpAgency</th> */}
                    {/* <th>isTopUpAgencyBlock</th> */}
                    {/* <th>isHostAgency</th> */}
                    {/* <th>isHostAgencyBlock</th> */}
                    {/* <th>Info</th> */}
                    {/* <th>History</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.serialId}</td>
                          {/* <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.image ? data?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />
                          </td> */}
                          <td>{data?.name}</td>
                          <td>{data?.phoneNo}</td>
                          <td>{data?.country}</td>
                          <td>{data?.city}</td>
                          {/* <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data.isBlock}
                                onChange={() =>
                                  handleBlockUnblockSwitch_(data._id)
                                }
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${data.isBlock ? "5px" : "35px"
                                      }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data.isBlock ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td> */}
                          {/* <td>{data.isTopUpAgency ? "Yes" : "No"}</td> */}
                          {/* change role  */}
                          {/* <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.isTopUpAgency}
                                onChange={() =>
                                  handleUpdateAgencyRole_(data?._id, "topup-masterAgency")
                                }
                              />
                              <span className="slider"
                                style={{ background: "#9c27b0" }}
                              >
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${data?.isTopUpAgency ? "5px" : "35px"
                                      }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.isTopUpAgency ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td> */}
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.isBlock}
                                // onChange={() =>
                                //   handleBlockUnblockSwitch_(data?._id)
                                // }
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${data?.isBlock ? "5px" : "35px"
                                      }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.isBlock ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          {/* <td>
                            <Tooltip title="Info">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleUserInfo(data)}
                              >
                                <i className="fas fa-info-circle fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td> */}
                          {/* <td>
                            <Tooltip title="History">
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => handleUserHistory(data)}
                              >
                                <i className="fas fa-history fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={props.total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { handleBlockUnblock, getAllMasterTopUpAgenciesForSuperAdmin, getAllMasterTopUpAgenciesForMaster })(
  MasterTopUpAgencyForMaster
);
