import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH_AGENCY,
  GET_AGENCY,
  GET_HISTORY,
  EDIT_COIN,
  REGISTER_AGENCY,
  REGISTER_AGENCY_FAILURE,
  SET_AGENCY,
  UNSET_AGENCY,
  UPDATE_PROFILE,
  GET_AGENCY_DETAILS,
  GET_ALL_AGENCY_HOSTS,
  ACTIVE_HOST_AGENCY,
  REJECT_HOST_AGENCY,
  GET_ALL_AGENCIES_MASTER,
  GET_AGENCIES_OF_SUPER_ADMIN,
  GET_MASTER_AGENCIES_OF_SUPER_ADMIN,
  GET_ALL_AGENCIES,
  GET_SALARY_SHEET_AGENCY,
} from "./types";
import { WebAppName, baseURL, key } from "../../util/Config";

export const register =
  ({ userData, onSuccess }) =>
  (dispatch) => {
    axios
      .post("agency/create-agency", userData)
      .then((res) => {
        if (res.data.status) {
          Toast(
            "success",
            `You have successfully registered as a Agency in ${WebAppName}. Please wait for approval.`
          );
          dispatch({ type: REGISTER_AGENCY, payload: res.data });
          onSuccess();
        } else {
          Toast("error", res.data.error.message);
          dispatch({ type: REGISTER_AGENCY_FAILURE });
        }
      })
      .catch((error) => {
        Toast("error", error.response.data.message);
        console.log("error", error.message);
        dispatch({ type: REGISTER_AGENCY_FAILURE });
      });
  };

export const getAgency =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { key: key },
    };
    fetch(
      `${baseURL}agency/agencies?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("response,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,", res);
        if (res.status) {
          dispatch({
            type: GET_AGENCY,
            payload: {
              agency: res.agencies,

              total: res.total,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const handleBlockUnblockSwitch =
  (agencyId, type_agency, masterId) => async (dispatch) => {
    try {
      const { data } = await axios.put(`/agency/blockUnblock/${agencyId}`, {
        type: type_agency,
        masterId,
      });
      Toast("success", data.message);
      if (data && data?.agencies) {
        dispatch({
          type: GET_ALL_AGENCIES_MASTER,
          payload: {
            agency: data?.agencies,
            total: data?.agencies?.length,
          },
        });
      }
    } catch (error) {
      Toast("error", error.message);
    }
  };

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const login = (data) => (dispatch) => {
  axios
    .post("agency/login", data)
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast(`success", "You have successfully logged in ${WebAppName}.`);
        setTimeout(() => {
          window.location.href = "/agency/dashboard";
        }, 3000);
        dispatch({ type: SET_AGENCY, payload: res.data.token });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("🚀 ~ file: action.js:22 ~ login ~ error:", error);
      return console.log(error);
    });
};
export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const getProfile = () => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .get("/agency/profile", {
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_PROFILE, payload: res.data.agency });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const getDetailsSubAdmin =
  (agencyId, start, limit, searchValue, sDate, eDate, status) =>
  async (dispatch) => {
    console.log("start------------------------line 355", start);
    console.log("limit------------------------line 356", limit);
    console.log("search value----------------", searchValue);
    console.log("start date------------------", sDate);
    console.log("end date--------------------", eDate);
    console.log("status--------------------", status);

    const authToken = localStorage.getItem("TOKEN");

    try {
      const response = await axios.get(
        `/agency/details/${agencyId}?status=${status}`,
        {
          params: {
            start,
            limit,
            search: searchValue,
            startDate: sDate,
            endDate: eDate,
          },
          headers: {
            Authorization: authToken,
          },
        }
      );
      console.log("response,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,", response);
      const { agency, message } = response.data;

      // if (status) {
      dispatch({
        type: GET_AGENCY_DETAILS,
        payload: {
          agency: agency,
          totalDiamondHistoryRecords:
            agency.topUpAgencyDetails.totalDiamondsHistoryRecords || 0,
        },
      });
      // }
      //   else {
      //     dispatch({
      //       type: GET_AGENCY_DETAILS,
      //       payload: {
      //         agency: {},
      //         totalDiamondHistoryRecords: 0,
      //       },
      //     });
      //     Toast("error", message);
      //   }
    } catch (error) {
      console.error("error", error.message);
      dispatch({
        type: GET_AGENCY_DETAILS,
        payload: {
          agency: {},
          totalDiamondHistoryRecords: 0,
        },
      });
    }
  };

export const changePassword = (data) => (dispatch) => {
  axios
    .put("agency/update-password", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_AGENCY });
          window.location.href = "/agency/login";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("agency/update-profile", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.agency });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
//get all agency hosts

//get all hosts
export const getAllAgencyHosts = () => (dispatch) => {
  let authToken = localStorage.getItem("TOKEN");
  axios
    .get("/agency/hosts", {
      headers: {
        Authorization: `${authToken}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: res.data.hosts });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};
//get all hosts for superAdmin and master admin
export const getAllAgencyHostsForAdmins =
  (agencyId, start, limit, searchValue, sDate, eDate) => (dispatch) => {
    console.log(
      "start------------------------",
      agencyId,
      start,
      limit,
      searchValue,
      sDate,
      eDate
    );
    // console.log("limitssssssssssssssssss------", limit);
    // console.log("search value------------", searchValue);
    // console.log("start datee------------------", sDate);
    // console.log("end dte-------------------------", eDate);

    axios
      .get(
        `/agency/hosts/${agencyId}?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`,
        {}
      )
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res);
        if (res.data.status) {
          dispatch({
            type: GET_ALL_AGENCY_HOSTS,
            payload: {
              hosts: res.data.hosts,
              total: res.data.total,
            },
          });
        } else {
          dispatch({ type: GET_ALL_AGENCY_HOSTS, payload: [] }); // Clear the data in the state
          // Toast("error", res.data.message);
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

//active host request
export const activeHost = (hostId) => (dispatch) => {
  console.log("hostId active in action", hostId);
  axios
    .put(`/agency/host/active/${hostId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ACTIVE_HOST_AGENCY,
          payload: res.data.agency,
        });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const rejectHost = (hostId) => (dispatch) => {
  axios
    .put(`/agency/host/reject/${hostId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: REJECT_HOST_AGENCY,
          payload: res.data.agency,
        });
        Toast("success", res.data.message);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

//getAllAgenciesForMaster
// export const getAllAgenciesForMaster =
//   (masterId, start, limit, searchValue, sDate, eDate) => (dispatch) => {
//     console.log(masterId);
//     axios
//       .get(
//         `/agency/agencies/${masterId}/?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
//       )
//       .then((res) => {
//         const agencies = res.data.agencies.length > 0 ? res.data.agencies : [];
//         const total = res.data.total || 0;

//         if (res.data.status) {
//           dispatch({
//             type: GET_ALL_AGENCIES_MASTER,
//             payload: {
//               agency: agencies,
//               total: total,
//             },
//           });
//         } else {
//           Toast("error", res.data.message);
//         }
//       })
//       .catch((error) => Toast("error", error.message));
//   };

export const getAllAgenciesForMaster =
  (masterId, start, limit, searchValue, sDate, eDate) => async (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    console.log("🚀 ~ superAdminId:", masterId);

    if (!masterId) {
      console.error("Super Admin ID is null or undefined");
      return;
    }
    try {
      const { data } = await axios.get(
        `/agency/agencies/${masterId}/?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log("🚀 ~ data:", data);
      console.log(data.total);
      dispatch({
        type: GET_ALL_AGENCIES_MASTER,
        payload: {
          agency: data.agencies,
          total: data.total,
        },
      });
    } catch (error) {
      Toast("error", error.response.data.message || error.response.data.error);
    }
  };

export const updateAgencyRole =
  (agencyId, type_agency, masterId) => async (dispatch) => {
    try {
      const { data } = await axios.put(`/agency/roles/${agencyId}`, {
        type: type_agency,
        masterId,
      });
      Toast("success", data.message);
      if (data && data?.agencies) {
        dispatch({
          type: GET_ALL_AGENCIES_MASTER,
          payload: {
            agency: data?.agencies,
            total: data?.agencies?.length,
          },
        });
      }
    } catch (error) {
      Toast("error", error.message);
    }
  };

//getAllAgenciesofSuperAdmin
export const getAllAgenciesForSuperAdmin =
  (superAdminId, start, limit, searchValue, sDate, eDate) =>
  async (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    console.log("🚀 ~ superAdminId:", superAdminId);

    if (!superAdminId) {
      console.error("Super Admin ID is null or undefined");
      return;
    }
    try {
      const { data } = await axios.get(
        `/super-admin/topUpHostingAgencies/list/${superAdminId}?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log(data.total);
      dispatch({
        type: GET_AGENCIES_OF_SUPER_ADMIN,
        payload: {
          agency: data?.topUpHostingagenciesList,
          total: data?.total,
        },
      });
    } catch (error) {
      Toast("error", error.response.data.message || error.response.data.error);
    }
  };
// console.log("🚀 ~ superAdminId:", superAdminId);
// console.log("🚀 ~ superAdminId:", superAdminId);
// console.log("🚀 ~ superAdminId:", superAdminId);
//getAllAgencies
export const getAllAgencies =
  (start, limit, searchValue, sDate, eDate) => async (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    try {
      const { data } = await axios.get(
        `agency/all?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log("data.......................... lini no 364", data);
      dispatch({
        type: GET_ALL_AGENCIES,
        payload: {
          agency: data?.agencies,
          total: data?.total,
        },
      });
    } catch (error) {
      console.log(error);
      // Toast("error", error.response.data.message || error.response.data.error);
    }
  };

//getAllMasterAgenciesForSuperAdmin
export const getAllMasterTopUpAgenciesForSuperAdmin =
  (superAdminId) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/super-admin/masterTopUpAgencies/list/${superAdminId}`
      );
      dispatch({
        type: GET_MASTER_AGENCIES_OF_SUPER_ADMIN,
        payload: {
          masterAgency: data?.masterTopUpAgencies,
          total: data?.masterTopUpAgencies?.length,
        },
      });
    } catch (error) {
      Toast("error", error.response.data.message || error.response.data.error);
    }
  };

export const getSalarySheetAgency = (agencyData) => async (dispatch) => {
  // Destructure the userData to get startTime and endTime if they exist
  const { startTime, endTime, agencyId } = agencyData;
  console.log("salarySheet?,,,,,,,,,,,,,,,,,,,,,,,,,,", agencyData);

  // Construct the URL with query parameters if startTime and endTime exist
  let url = `/agency/salarySheet/${agencyId}`;
  if (startTime && endTime) {
    url += `?startTime=${startTime}&endTime=${endTime}`;
  }
  try {
    const { data } = await axios.get(url);

    dispatch({ type: GET_SALARY_SHEET_AGENCY, payload: data });
  } catch (error) {
    console.log(error.message || error.response.data.message);
  }
};
