import React from "react";

const DiamondHistoryTable = (props) => {
  // console.log("🚀 ~ DiamondHistoryTable ~ props:", props.status);
  // console.log("diamond history line no 5", props.data);
  // console.log("diamond history line no 5", props.status);

  const data = Array.isArray(props.data) ? props.data : [];
  // console.log("🚀 ~ DiamondHistoryTable ~ data:", data);
  const filteredData = data.filter((item) => {
    // console.log("🚀 ~ DiamondHistoryTable ~ item:", item.status  )
    return item.status === props.status
  })
  // console.log("🚀 ~ DiamondHistoryTable ~ filteredData:", filteredData);
  // console.log("🚀 ~ DiamondHistoryTable ~ filteredData:", filteredData)

  // console.log(filteredData);

  return (
    <table className="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>{props.status === "sent" ? "Send to" : "Receive from"}</th>
          <th>Email</th>
          <th>User Name</th>
          <th>Diamond</th>
          <th>Transfer Date</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.length > 0 ? (
          filteredData.map((data, index) => {
            const recordNumber =
              index + 1 + (props.activePage - 1) * props.rowsPerPage;
            return (
              <tr key={index}>
                {/* <td>{index + 1}</td>   */}
                <td>{recordNumber}</td>

                <td>{data.role}</td>
                <td>{data.email}</td>
                <td className="text-info">{data.name}</td>
                <td>{data.quantity}</td>
                <td>{data.createdAt}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DiamondHistoryTable;
