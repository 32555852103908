export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

export const SEND_EMAIL = "SEND_EMAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const GET_ALL_MASTERS_OF_SUPER_ADMIN = "GET_ALL_MASTERS_OF_SUPER_ADMIN";
export const GET_ALL_MASTER_HOSTING_TOPUP_AGENCIES_OF_ADMIN = "GET_ALL_MASTER_HOSTING_TOPUP_AGENCIES_OF_ADMIN";
export const GET_ALL_MASTER_TOPUP_AGENCIES_OF_ADMIN = "GET_ALL_MASTER_TOPUP_AGENCIES_OF_ADMIN";

export const GET_PROFILE_MASTER = "GET_PROFILE_MASTER";
export const BLOCK_UNBLOCK_MASTER_ADMIN  = "BLOCK_UNBLOCK_MASTER_ADMIN";
export  const SIGNUP_ADMIN ="SIGNUP_ADMIN"

