import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";

import { SET_SUPER_ADMIN, UNSET_SUPER_ADMIN, UPDATE_PROFILE } from "./types";

const initialState = {
  isSuperAdmin: false,
  superAdmin: {},
  total: 0,
};

const superAdminReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case SET_SUPER_ADMIN:
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      return {
        ...state,
        isSuperAdmin: true,
        superAdmin: decoded,
      };

    case UNSET_SUPER_ADMIN:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("superAdmin");
      localStorage.removeItem("KEY");
      localStorage.removeItem("firstLoad");
      localStorage.removeItem("CategoryDetail");
      localStorage.removeItem("CategoryId");
      localStorage.removeItem("PostDetail");
      localStorage.removeItem("VideoDetail");
      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isSuperAdmin: false,
        superAdmin: {},
        total: 0,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        superAdmin: {
          ...state.superAdmin,
          ...action.payload.superAdmin,
        },
        total: action.payload.total, // Update total
      };
    default:
      return state;
  }
};

export default superAdminReducer;
