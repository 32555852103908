export const SET_SUPER_ADMIN = "SET_SUPER_ADMIN";
export const UNSET_SUPER_ADMIN = "UNSET_SUPER_ADMIN";

export const SEND_EMAIL = "SEND_EMAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";


export  const SIGNUP_SUPER_ADMIN ="SIGNUP_SUPER_ADMIN"

