import {
  REGISTER_MASTER_AGENCY,
  REGISTER_MASTER_AGENCY_FAILURE,
  GET_MASTER_AGENCY_DETAILS,
  GET_MASTER_AGENCY_LIST_SUPER_MASTER,
  GET_MASTER_TOP_UP_AGENCIES_OF_SUPER_ADMIN,
  BLOCK_UNBLOCK_MASTER_AGENCY,
  GET_MASTER_TOP_UP_AGENCIES,
  GET_MASTER_TOP_UP_DIAMOND_HISTORY,
} from "./types";

// import setToken from "../../util/SetToken";
// import setDevKey from "../../util/SetDevKey";
// import jwt_decode from "jwt-decode";
// import { key } from "../../util/Config";

const initialState = {
  isAuth: false,
  masterAgency: [],
  totalMasterAgency: 0,
};

const masterAgencyReducer = (state = initialState, action) => {
  // let decoded;

  switch (action.type) {
    case REGISTER_MASTER_AGENCY:
      return {
        ...state,
        masterAgency: action.payload.masterAgency,
      };

    case REGISTER_MASTER_AGENCY_FAILURE:
      return state;
    case GET_MASTER_AGENCY_LIST_SUPER_MASTER:
      return {
        ...state,
        masterAgencyList: action.payload.masterAgencyList,
        totalMasterAgency: action.payload.total,
      };
    case GET_MASTER_TOP_UP_AGENCIES_OF_SUPER_ADMIN:
      return {
        ...state,
        masterAgency: action.payload.masterAgency,
        total: action.payload.total,
      };
    case GET_MASTER_TOP_UP_AGENCIES:
      return {
        ...state,
        masterAgencies: action.payload.masterAgency,
        totalMasterAgencies: action.payload.total,
      };
    case GET_MASTER_AGENCY_DETAILS:
      return {
        ...state,
        masterAgency: action.payload,
      };
    case GET_MASTER_TOP_UP_DIAMOND_HISTORY:
      return {
        ...state,
        masterDiamondHistory: action.payload.masterDiamondHistory,
        total: action.payload.total,
      };
    case BLOCK_UNBLOCK_MASTER_AGENCY:
      return {
        ...state,
        masterAgency: state.masterAgency.map((admin) => {
          if (admin._id === action.payload._id)
            return {
              ...admin,
              isBlock: action.payload.isBlock,
            };
          else return admin;
        }),
      };
    default:
      return state;
  }
};

export default masterAgencyReducer;
