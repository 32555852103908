import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  GET_PERMISSIONS_OF_MASTER,
  UPDATE_ASSIGN_PERMISSIONS_TO_MASTER,
} from "./types";
import { WebAppTitle } from "../../util/Config";

export const getPermissionsForMaster = (role, Id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/permissions/${role}/${Id}`);
    dispatch({ type: GET_PERMISSIONS_OF_MASTER, payload: data.permissions });
    console.log("🚀 ~ data:", data);
    Toast("success", data);
    console.log("data", data);
  } catch (error) {
    console.log("🚀 ~ error:", error);
    Toast("error", error);
  }
};

export const assignUpdatePermissions =
  (permissionsData) => async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/permissions/update-assign`,
        permissionsData
      );
      Toast("success", data.message);
    } catch (error) {
      console.log("🚀 ~ error:", error);
      Toast("error", error);
    }
  };
