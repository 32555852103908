import { CLOSE_LEVEL_DIALOG, OPEN_LEVEL_DIALOG } from "../level/types";
import {
  GET_FRAME,
  CREATE_NEW_FRAME,
  OPEN_FRAME_DIALOG,
  CLOSE_FRAME_DIALOG,
  EDIT_FRAME,
  DELETE_FRAME,
} from "./types";

const initialState = {
  frame: [],
  dialog: false,
  dialogData: null,
};

const frameReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FRAME:
      return {
        ...state,
        frame: action.payload,
      };
    case CREATE_NEW_FRAME:
      const data = [...state.frame];
      data.unshift(action.payload);
      return {
        ...state,
        frame: data,
      };
    case EDIT_FRAME:
      return {
        ...state,
        frame: state.frame.map((frame) => {
          if (frame._id === action.payload.id) return action.payload.data;
          else return frame;
        }),
      };
    case DELETE_FRAME:
      return {
        ...state,
        frame: state.frame.filter((frame) => frame._id !== action.payload),
      };
    case OPEN_FRAME_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_FRAME_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default frameReducer;
