import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HOST,
  GET_HISTORY,
  EDIT_COIN,
  GET_USER_BY_STATUS,
  SET_USER_STATUS,
} from "./types";

const initialState = {
  user: [],
  male: 0,
  female: 0,
  totalUser: 0,
  activeUser: 0,
  pendingUser: 0,
  blockedUser: 0,
  history: [],
  totalHistoryUser: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
  userDataByStatus: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload.user,
        male: action.payload.male,
        female: action.payload.female,
        totalUser: action.payload.total,
        activeUser: action.payload.activeUser.length,
        pendingUser: action.payload.pendingUser.length,
        blockedUser: action.payload.blockedUser.length,
      };
    case GET_HOST:
      return {
        ...state,
        user: action.payload.user,
        male: action.payload.male,
        female: action.payload.female,
        totalUser: action.payload.total,
        activeUser: action.payload.activeUser.length,
        pendingUser: action.payload.pendingUser.length,
        blockedUser: action.payload.blockedUser.length,
      };

    case BLOCK_UNBLOCK_SWITCH:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload._id)
            return {
              ...user,
              isBlock: action.payload.isBlock,
            };
          else return user;
        }),
      };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload.id) return action.payload.data;
          else return user;
        }),
      };

    case GET_USER_BY_STATUS:
      return {
        ...state,
        userDataByStatus: action?.payload?.userDataByStatus ?? [],
      };

    case SET_USER_STATUS:
      const usersUpdatedData = state.user.map((user) => {
        if (user._id === action.payload.data.userId) {
          return {
            ...user,
            status: action.payload.data.status,
          };
        }
        return user;
      });
      return {
        ...state,
        user: usersUpdatedData,
      };

    default:
      return state;
  }
};

export default userReducer;
