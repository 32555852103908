import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_MASTER_AGENCY,
  GET_MASTER_AGENCY_LIST_SUPER_MASTER,
  GET_MASTER_TOP_UP_AGENCIES,
  GET_MASTER_TOP_UP_AGENCIES_OF_SUPER_ADMIN,
  GET_MASTER_TOP_UP_DIAMOND_HISTORY,
  REGISTER_MASTER_AGENCY,
  REGISTER_MASTER_AGENCY_FAILURE,
} from "./types";

import { WebAppName, baseURL, key } from "../../util/Config";

export const masterAgencyRegister =
  ({ userData, onSuccess }) =>
  (dispatch) => {
    axios
      .post("/master-agency/create-agency", userData)
      .then((res) => {
        if (res.data.status) {
          Toast(
            "success",
            `You have successfully registered as a Agency in ${WebAppName}. Please wait for approval.`
          );
          dispatch({ type: REGISTER_MASTER_AGENCY, payload: res.data });
          onSuccess();
        } else {
          Toast("error", res.data.message);
          dispatch({ type: REGISTER_MASTER_AGENCY });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: REGISTER_MASTER_AGENCY_FAILURE });
      });
  };

export const getMasterAgencyListSuperMaster =
  (masterId, start, limit, searchValue, sDate, eDate) => (dispatch) => {
    axios
      .get(
        `/master-agency/agencies/${masterId}?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      )
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        if (res.data.status) {
          dispatch({
            type: GET_MASTER_AGENCY_LIST_SUPER_MASTER,
            payload: {
              masterAgencyList: res.data.masterAgencies,
              total: res.data?.total,
            },
          });
        } else {
          dispatch({
            type: GET_MASTER_AGENCY_LIST_SUPER_MASTER,
            payload: {
              masterAgencyList: [], // Empty the list
              total: 0, // Optionally reset the total count
            },
          });
          // Toast("error", res.data.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MASTER_AGENCY_LIST_SUPER_MASTER,
          payload: {
            masterAgencyList: [], // Empty the list in case of an error
            total: 0, // Optionally reset the total count
          },
        });
        // Toast("error", error.response.data.message);
        console.log(error);
      });
  };

export const handleBlockUnblockSwitch =
  (agencyId, masterId) => async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/master-agency/blockUnblock/${agencyId}`,
        { masterId }
      );
      Toast("success", data.message);
      if (data && data?.masterAgencies) {
        dispatch({
          type: GET_MASTER_AGENCY_LIST_SUPER_MASTER,
          payload: {
            masterAgencyList: data?.masterAgencies,
            total: data?.masterAgencies?.length,
          },
        });
      }
    } catch (error) {
      Toast("error", error.response.data.message || error.message);
    }
  };

//getAllMasterAgenciesForSuperAdmin
export const getAllMasterTopUpAgenciesForSuperAdmin =
  (superAdminId, start, limit, searchValue, sDate, eDate) =>
  async (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    try {
      const { data } = await axios.get(
        `/super-admin/masterTopUpAgencies/list/${superAdminId}?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log("🚀 ~ data:", data);
      dispatch({
        type: GET_MASTER_TOP_UP_AGENCIES_OF_SUPER_ADMIN,
        payload: {
          masterAgency: data?.masterTopUpAgencies,
          total: data?.total,
        },
      });
    } catch (error) {
      // Toast("error", error.response.data.message || error.response.data.error);
    }
  };
//getAllMasterAgencie
export const getAllMasterTopUpAgencies =
  (start, limit, searchValue, sDate, eDate) => async (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    // if (!superAdminId) {
    //   console.error("Super Admin ID is null or undefined");
    //   return;
    // }
    try {
      const { data } = await axios.get(
        `/master-agency/all?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log(
        "data,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,",
        data
      );
      dispatch({
        type: GET_MASTER_TOP_UP_AGENCIES,
        payload: {
          masterAgency: data?.masterAgencies,
          total: data?.total, //change to total
        },
      });
    } catch (error) {
      console.log("error", error.response.data.message);
      // Toast("error", error.response.data.message || error.response.data.error);
    }
  };

export const handleBlockUnblock = (agencyId) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `/master-agency/blockUnblock/agency/${agencyId}`
    );
    Toast("success", data.message);
    if (data && data?.masterAgency) {
      dispatch({
        type: BLOCK_UNBLOCK_MASTER_AGENCY,
        payload: data.masterAgency,
      });
    }
  } catch (error) {
    Toast("error", error.message);
  }
};
//getAllMasterAgenciesForSuperAdmin
export const getDiamondHistoryMasterTopUpAgency =
  (status, masterAgencyId, start, limit, searchValue, sDate, eDate) =>
  async (dispatch) => {
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    // console.log("search value------------", searchValue);
    // console.log("start datee------------------", sDate);
    // console.log("end dte-------------------------", eDate);
    console.log(masterAgencyId);
    try {
      const { data } = await axios.get(
        `/master-agency/diamond-history/${masterAgencyId}?status=${status}&start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log("data,,,,,,,,,,,,,,,,,,", data.diamondsHistory);
      dispatch({
        type: GET_MASTER_TOP_UP_DIAMOND_HISTORY,
        payload: {
          masterDiamondHistory: data,
          total: data?.total, //change to total
        },
      });
    } catch (error) {
      Toast("error", error.response.data.message || error.response.data.error);
    }
  };
