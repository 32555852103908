import React, { useEffect, useState } from "react";

//redux
import { connect, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//action
import { getProfile } from "../../store/admin/action";
import { useRouteMatch } from "react-router-dom";
//pagination
import Pagination from "../../pages/Pagination";

//routing
import { Link } from "react-router-dom";

import DiamondHistoryTable from "./DiamondHistoryTable";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";

const MasterDiamondHistory = (props) => {
  const match = useRouteMatch();
  const status = match.params.status;
  //   console.log("🚀 ~ MasterDiamondHistory ~ status:", status);

  const master = JSON.parse(localStorage.getItem("admin"));

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const [date, setDate] = useState([]);
  // useEffect(() => {
  //   console.log("useefeect is called,,,,,,,,,,,,,,,,");
  //   props.getProfile(status, activePage, rowsPerPage, search, sDate, eDate);
  // }, [status, activePage, rowsPerPage, search, sDate, eDate]);
  useEffect(() => {
    console.log("useEffect is called");
    // Check if parameters are valid before calling getProfile
    if (
      typeof status !== "undefined" &&
      typeof activePage !== "undefined" &&
      typeof rowsPerPage !== "undefined" &&
      typeof search !== "undefined" &&
      typeof sDate !== "undefined" &&
      typeof eDate !== "undefined"
    ) {
      props.getProfile(status, activePage, rowsPerPage, search, sDate, eDate);
    }
  }, [status, activePage, rowsPerPage, search, sDate, eDate]);
  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
  }, [date]);
  const getAllDiamondHistoryForAdmin = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker");
    getProfile(status, activePage, rowsPerPage, search, sDate, eDate);
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const { admin, total } = useSelector((state) => state.admin);
  const diamondStatusHistory = admin?.diamondsHistory;
  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white ">{`${
              status.charAt(0).toUpperCase() + status.slice(1)
            } Diamond History`}</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Diamond
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllDiamondHistoryForAdmin}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </div>
                  </form>
                </div>
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0"></div> */}
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("YYYY/MM/DD");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "YYYY/MM/DD"
                          );
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                          //   props.vipPlanHistory(
                          //     null,
                          //     activePage,
                          //     rowsPerPage,
                          //     sDate,
                          //     eDate
                          //   );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <DiamondHistoryTable
                data={diamondStatusHistory}
                status={status}
                activePage={activePage}
                rowsPerPage={rowsPerPage}
              />
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getProfile })(MasterDiamondHistory);
