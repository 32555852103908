import React, { useEffect } from "react";

//redux
import { connect, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { getAllAgencyHosts } from '../../store/agency/action'
import HostList from '../../component/Agency/HostList';

function AgencyHostsTableByStatus(props) {
  const match = useRouteMatch();
  // const history = useHistory();
  const status = match.params.status.split(":")[1];
  const { hosts } = useSelector((state) => state.agency);
  useEffect(() => {
    props.getAllAgencyHosts();
  }, [status]);

  const statusHosts = hosts?.filter((host) => host.hostRequest.status === status);

  return <>
    <div className="page-title">
      <div className="row">
        <div className="col-12 col-md-6 order-md-1 order-last">
          <h3 className="mb-3" style={{ color: "#e4eeff" }}>
            Host {status}
          </h3>
        </div>
      </div>
    </div>
    <HostList user={statusHosts} />
  </>
}

export default connect(null, { getAllAgencyHosts })(AgencyHostsTableByStatus);
