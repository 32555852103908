import React, { useState } from "react";

//routing
import { Link } from "react-router-dom";
import { WebAppName } from "../util/Config";

const HomePage = () => {
  return (
    <>
      <div className="login-page back__style">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-12 col-lg-4">
              <div class="card login-box-container">
                <div class="card-body">
                  <div class="authent-logo mb-4">
                    <span class="text-danger h1">{WebAppName}</span>
                  </div>
                  <div class="authent-text">
                    {/* <p>Welcome to Bigee Live</p> */}
                    <p>Login as</p>
                  </div>

                  <form autoComplete="off">
                    <Link to="/login">
                      <div class="d-grid">
                        <button type="submit" class="btn btn-danger m-b-xs">
                          Company
                        </button>
                      </div>
                    </Link>
                    <Link to="/subadmin/login">
                      <div class="d-grid">
                        <button type="submit" class="btn btn-danger m-b-xs">
                          Admin
                        </button>
                      </div>
                    </Link>
                    <Link to="/agency/login">
                      <div class="d-grid">
                        <button type="submit" class="btn btn-danger m-b-xs">
                          Agency
                        </button>
                      </div>
                    </Link>
                    <Link to="/top-seller/login">
                      <div class="d-grid">
                        <button type="submit" class="btn btn-danger m-b-xs">
                          Top-Seller
                        </button>
                      </div>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
