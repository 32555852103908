import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect, useSelector } from "react-redux";
import Pagination from "../../pages/Pagination";
import { getSalarySheetAgency } from "../../store/agency/action";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CSVLink } from "react-csv";

const SalarySheet = (props) => {
  const { agencyId } = props;
  console.log(agencyId);
  useEffect(() => {
    props.getSalarySheetAgency({ agencyId });
  }, [agencyId]);

  const { salarySheet } = useSelector((state) => state?.agency);
  const { agency } = useSelector((state) => state?.agency);
  console.log(agency);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [search, setSearch] = useState("ALL");
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const getAllUser = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
  };

  let headers = [
    { label: "Name", key: "name" },
    { label: "Unique ID", key: "uniqueId" },
    { label: "Total Days", key: "totalDays" },
    { label: "Total Hrs", key: "totalHrs" },
    { label: "Coin Target", key: "target" },
    { label: "Coin Share", key: "coinShare" },
    { label: "Basic Salary", key: "basicSalary" },
    { label: "Total Salary", key: "totalSalary" },
    { label: "Host Salary", key: "hostSalary" },
    { label: "Agency Commission", key: "agencyCommission" },
  ];

  let data =
    salarySheet && salarySheet.salarySheet ? salarySheet.salarySheet : [];

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <button className="btn btn-info" style={{ marginRight: 5 }}>
              <CSVLink
                className="csv-link"
                data={data}
                filename={`${agency.serialId}_${agency.name}`}
                headers={headers}
              >
                Download CSV
              </CSVLink>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      // onClick={getAllUser}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      user-toggle="collapse"
                      user-target="#datePicker"
                      // onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Name</th>
                    <th>UniqueID</th>
                    <th>TotalDays</th>
                    <th>TotalHrs</th>
                    <th>CoinTarget</th>
                    <th>CoinShare</th>
                    <th>BasicSalary</th>
                    <th>TotalSalary</th>
                    <th>HostSalary</th>
                    <th>Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {salarySheet?.salarySheet?.length > 0 ? (
                    salarySheet?.salarySheet?.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <th>{user?.name}</th>

                          <td>{user?.uniqueId}</td>
                          <td>{user?.totalDays}</td>
                          <td>{user?.totalhrs}</td>
                          <td>{user?.target}</td>
                          <td>${user?.coinShare}</td>
                          <td>${user?.basicSalary}</td>
                          <td>${user?.totalSalary}</td>
                          <td>${user?.hostSalary}</td>
                          <td>${user?.agencyCommission}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                  {salarySheet?.salarySheet?.length > 0 && (
                    <tr>
                      <td colSpan="7"></td>
                      <td className="text-danger">HostSalary:</td>
                      <td>${salarySheet?.totalHostSalary || 0}</td>
                      <td className="text-danger">Commission:</td>
                      <td>${salarySheet?.totalAgencyCommission || 0}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                // userTotal={user?.length}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getSalarySheetAgency })(SalarySheet);
