import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";

import {
  BLOCK_UNBLOCK_MASTER_ADMIN,
  GET_ALL_MASTER_HOSTING_TOPUP_AGENCIES_OF_ADMIN,
  GET_ALL_MASTER_TOPUP_AGENCIES_OF_ADMIN,
  GET_PROFILE_MASTER,
  SET_ADMIN,
  UNSET_ADMIN,
  UPDATE_PROFILE,
} from "./types";
import { GET_MASTER_ADMINS_OF_SUPER_ADMIN } from "../agency/types";

const initialState = {
  isAuth: false,
  admin: {},
  total: 0,
};

const adminReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case SET_ADMIN:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      return {
        ...state,
        isAuth: true,
        admin: decoded,
      };

    case UNSET_ADMIN:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("admin");
      localStorage.removeItem("KEY");
      localStorage.removeItem("firstLoad");
      localStorage.removeItem("CategoryDetail");
      localStorage.removeItem("CategoryId");
      localStorage.removeItem("PostDetail");
      localStorage.removeItem("VideoDetail");
      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth: false,
        admin: {},
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        admin: {
          ...state.admin,
          ...action.payload.admin,
        },
        total: action.payload.total,
      };
    case GET_PROFILE_MASTER:
      return {
        ...state,
        adminProfile: {
          ...state.admin,
          ...action.payload,
        },
      };
    case GET_MASTER_ADMINS_OF_SUPER_ADMIN:
      return {
        ...state,
        masterAdmin: action.payload.masterAdmin,
        total: action.payload.total,
      };
    case GET_ALL_MASTER_HOSTING_TOPUP_AGENCIES_OF_ADMIN:
      return {
        ...state,
        agenciesList: action.payload.agencies,
        total: action.payload.total,
      };
    case GET_ALL_MASTER_TOPUP_AGENCIES_OF_ADMIN:
      return {
        ...state,
        masterAgenciesList: action.payload.agencies,
        masterAgenciesTotal: action.payload.total,
      };
    case BLOCK_UNBLOCK_MASTER_ADMIN:
      return {
        ...state,
        masterAdmin: state.masterAdmin.map((admin) => {
          if (admin._id === action.payload._id)
            return {
              ...admin,
              isBlock: action.payload.isBlock,
            };
          else return admin;
        }),
      };
    default:
      return state;
  }
};

export default adminReducer;
