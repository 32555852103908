import setToken from "../../util/SetToken";
import setDevKey from "../../util/SetDevKey";
import jwt_decode from "jwt-decode";
import { key } from "../../util/Config";

import {
  GET_PERMISSIONS_OF_MASTER,
  ASSIGN_PERMISSIONS_TO_MASTER
} from "./types";

const initialState = {
  loading: true,
  adminPermission: [],
};

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_OF_MASTER:

      return {
        ...state,
        loading: false,
        adminPermission: action.payload,
      };
    default:
      return state;
  }
};

export default permissionReducer;
