import React, { useEffect, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useSelector } from "react-redux";

//action
// import {
//   getUser,
//   handleBlockUnblockSwitch,
//   getUserByStatus,
// } from "../../store/hosts/action";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

//image
import Male from "../../assets/images/male.png";

import { permissionError } from "../../util/Alert";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HostList from '../Agency/HostList';
import { getAllAgencyHosts } from '../../store/agency/action';

const AgencyHostsTable = (props) => {
  useEffect(() => {
    props.getAllAgencyHosts();
  }, [])

  const history = useHistory();

  const hasPermission = useSelector((state) => state.admin.admin.flag);
  // console.log("🚀 ~ AgencyHostsTable ~ hasPermission:", hasPermission);
  const { hosts } = useSelector((state) => state.agency);
  console.log("🚀 ~ hosts ~ hosts:", hosts);
  // const [coinSort, setCoinSort] = useState(true);
  // const [followerSort, setFollowerSort] = useState(true);
  // const [followingSort, setFollowingSort] = useState(true);
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");

  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker");
    });
  }, []);

  // useEffect(() => {
  //   props.getUser(activePage, rowsPerPage, search, sDate, eDate); // eslint-disable-next-line
  // }, [activePage, rowsPerPage, search, sDate, eDate]);

  // const { hosts, activeUser, pendingUser, blockedUser, totalUser } = useSelector(
  //   (state) => state.hosts
  // );
  // console.log("🥰🐶🐠🐬🦋⚽️===>", hosts);
  // useEffect(() => {
  //   setData(hosts);
  // }, [hosts]);

  // useEffect(() => {
  //   if (date.length === 0) {
  //     setDate([
  //       {
  //         startDate: new Date(),
  //         endDate: new Date(),
  //         key: "selection",
  //       },
  //     ]);
  //   }
  //   $("#datePicker");
  //   setData(hosts);
  // }, [date, hosts]);

  // const handlePageChange = (pageNumber) => {
  //   setActivePage(pageNumber);
  // };

  // const handleRowsPerPage = (value) => {
  //   setActivePage(1);
  //   setRowsPerPage(value);
  // };

  // const handleBlockUnblockSwitch_ = (userId) => {
  //   if (!hasPermission) return permissionError();
  //   props.handleBlockUnblockSwitch(userId);
  // };

  // const handleUserInfo = (hosts) => {
  //   localStorage.setItem("hosts", JSON.stringify(hosts));
  //   history.push("/admin/hosts/detail");
  // };
  // const handleUserHistory = (hosts) => {
  //   localStorage.setItem("hosts", JSON.stringify(hosts));
  //   history.push("/admin/hosts/history");
  // };

  // const getAllUser = () => {
  //   setActivePage(1);
  //   setsDate("ALL");
  //   seteDate("ALL");
  //   $("#datePicker");
  //   props.getUser(activePage, rowsPerPage, sDate, eDate);
  // };

  // const collapsedDatePicker = () => {
  //   $("#datePicker").toggleClass("collapse");
  // };

  const activeHosts = hosts?.filter((host) => host.hostRequest.status === 'approved');
  const pendingHosts = hosts?.filter((host) => host.hostRequest.status === 'pending');
  const rejectedHosts = hosts?.filter((host) => host.hostRequest.status === 'rejected');

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Hosts
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/agency/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Hosts
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-2 col-sm-12">
          <div class="row">
            <div class="col-lg-4">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                  onClick={() => {
                    history.push("/agency/hosts/:approved");
                  }}
                >
                  <div class="stats-info">
                    <h5 class="card-title">
                      {activeHosts?.length || 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Active</p>
                  </div>
                  <div class="stats-icon change-danger">
                    <i class="material-icons">male</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                  onClick={() => {
                    history.push("/agency/hosts/:pending");
                  }}
                >
                  <div class="stats-info">
                    <h5 class="card-title">
                      {pendingHosts?.length || 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Pending</p>
                  </div>
                  <div class="stats-icon change-success">
                    <i class="material-icons">female</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card stats-card">
                <div
                  class="card-body pointer-cursor"
                  onClick={() => {
                    history.push("/agency/hosts/:rejected");
                  }}
                >
                  <div class="stats-info">
                    <h5 class="card-title">{rejectedHosts?.length || 0}</h5>
                    <p class="stats-text">Blocked</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">people</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <HostList user={activeHosts} />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  // getUser,
  // handleBlockUnblockSwitch,
  // getUserByStatus,
  getAllAgencyHosts,
})(AgencyHostsTable);
