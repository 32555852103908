import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  BLOCK_UNBLOCK_MASTER_ADMIN,
  GET_ALL_MASTER_HOSTING_TOPUP_AGENCIES_OF_ADMIN,
  GET_ALL_MASTER_TOPUP_AGENCIES_OF_ADMIN,
  GET_PROFILE_MASTER,
  SET_ADMIN,
  SIGNUP_ADMIN,
  UNSET_ADMIN,
  UPDATE_PROFILE,
} from "./types";
import { WebAppTitle } from "../../util/Config";
import { GET_MASTER_ADMINS_OF_SUPER_ADMIN } from "../agency/types";

export const signupAdmin =
  ({ signup, onSuccess }) =>
  async (dispatch) => {
    try {
      const res = await axios.post("/admin/signup", signup);
      console.log("this is res", res);
      if (res.data.status) {
        Toast("success", "Master Admin Created Successfully!");
        onSuccess();
      } else {
        Toast("error", res.data.message);
      }
    } catch (error) {
      console.log("🚀 ~ error:", error);
      Toast("error", error);
    }
  };

export const login = (data) => (dispatch) => {
  axios
    .post("admin/login", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", `You have successfully logged in ${WebAppTitle}.`);
        setTimeout(() => {
          window.location.href = "/admin/dashboard";
        }, 3000);
        dispatch({ type: SET_ADMIN, payload: res.data.token });
        localStorage.setItem("admin", JSON.stringify(res.data));
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {});
};

export const sendEmail = (data) => (dispatch) => {
  axios
    .post("admin/sendEmail", data)
    .then((res) => {
      if (res.data.status) {
        Toast(
          "success",
          "Mail has been sent successfully. Sometimes mail has been landed on your spam!"
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

// export const getProfile =
//   (status, start, limit, searchValue, sDate, eDate) => (dispatch) => {
//     console.log("start------------------------lino 355", start);
//     console.log("limitssssssssssssssssss------356", limit);
//     console.log("search value------------", searchValue);
//     console.log("start datee------------------", sDate);
//     console.log("end dte-------------------------", eDate);
//     console.log("status-------------------------", status);
//     axios
//       .get(
//         `admin/profile?status=${status}&start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
//       )
//       .then((res) => {
//         console.log("🚀 ~ .then ~ res:,,,,,,,,,,,,", res.data.admin);
//         if (res.data.status) {
//           dispatch({
//             type: UPDATE_PROFILE,
//             payload: {
//               admin: res.data.admin,
//               total: res.data.admin.totalDiamondHistoryRecords, //change to total
//             },
//           });
//         } else {
//           Toast("error", res.data.message);
//         }
//       })
//       .catch((error) => {
//         console.log("error", error.message);
//       });
//   };
export const getProfile =
  (status, start, limit, searchValue, sDate, eDate) => (dispatch) => {
    // Check if all parameters are defined
    if (
      status !== undefined &&
      start !== undefined &&
      limit !== undefined &&
      searchValue !== undefined &&
      sDate !== undefined &&
      eDate !== undefined
    ) {
      console.log("start------------------------lino 355", start);
      console.log("limitssssssssssssssssss------356", limit);
      console.log("search value------------", searchValue);
      console.log("start datee------------------", sDate);
      console.log("end dte-------------------------", eDate);
      console.log("status-------------------------", status);

      axios
        .get(`admin/profile`, {
          params: {
            status: status,
            start: start,
            limit: limit,
            search: searchValue,
            startDate: sDate,
            endDate: eDate,
          },
        })
        .then((res) => {
          console.log("🚀 ~ .then ~ res:,,,,,,,,,,,,", res.data.admin);
          if (res.data.status) {
            dispatch({
              type: UPDATE_PROFILE,
              payload: {
                admin: res.data.admin,
                total: res.data.admin.totalDiamondHistoryRecords, // Assuming totalDiamondHistoryRecords is correct
              },
            });
          } else {
            Toast("error", res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    } else {
      console.log("Not all parameters are defined. API call skipped.");
    }
  };
export const getProfileForSuperAdmin = (masterId) => (dispatch) => {
  axios
    .get(`/admin/profile/${masterId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_PROFILE_MASTER, payload: res.data.admin });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};
export const changePassword = (data) => (dispatch) => {
  axios
    .put("admin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_ADMIN });
          window.location.href = "/";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("admin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: { admin: res.data.admin } });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateCode = (signup) => (dispatch) => {
  axios
    .patch("admin/updateCode", signup)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        console.log("res.data", res.data);
        Toast("success", "Purchase Code Update Successfully !");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};

export const sendDiamondsFromMaster = (bodydata) => async (dispatch) => {
  console.log("data send tranfer,,,,,,,,,,,,,", bodydata);
  try {
    const { data } = await axios.post(`/admin/transfer/coins`, bodydata);
    console.log("data,,,,,,,,,,,,,", data.admin);
    if (data.success) {
      Toast("success", data.message);
      dispatch({ type: UPDATE_PROFILE, payload: { admin: data.admin } });
    } else {
      Toast("error", data.message);
    }
  } catch (error) {
    console.log("error", error.message);
    Toast("error", error.message);
  }
};

//getAllMasterAdminForSuperAdmin
export const getAllMastersAdminListForSuperAdmin =
  (superAdminId, start, limit, searchValue, sDate, eDate) =>
  async (dispatch) => {
    console.log("superAdminId,,,,,,,,,,,,,,,,,,,,,,,,,,,,,", superAdminId);
    console.log("start------------------------lino 355", start);
    console.log("limitssssssssssssssssss------356", limit);
    console.log("search value------------", searchValue);
    console.log("start datee------------------", sDate);
    console.log("end dte-------------------------", eDate);
    try {
      const { data } = await axios.get(
        `/super-admin/masterAdmin/list/${superAdminId}?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      );
      console.log("data,,,,,,,,,,,,,,,,,,,,,,,,,,,,", data);
      dispatch({
        type: GET_MASTER_ADMINS_OF_SUPER_ADMIN,
        payload: {
          masterAdmin: data?.masterAdminList,
          total: data?.masterAdminList?.length,
        },
      });
    } catch (error) {
      console.log("error", error.response.data.message);

      // Toast("error", error.response.data.message || error.response.data.error);
    }
  };

//getAllAgenciesForMasterAdmin
export const getAllTopUpHostingAgenciesForMaster =
  (masterId) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/admin/topUpHostingAgencies/${masterId}`
      );
      dispatch({
        type: GET_ALL_MASTER_HOSTING_TOPUP_AGENCIES_OF_ADMIN,
        payload: {
          agencies: data?.agenciesList,
          total: data?.agenciesList?.length,
        },
      });
    } catch (error) {
      console.log("error", error.response.data.message);
      Toast("error", error.response.data.message || error.response.data.error);
    }
  };
//getAllMasterTopUpAgenciesForMaster
export const getAllMasterTopUpAgenciesForMaster =
  (masterId) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/admin/masterTopUpAgencies/${masterId}`
      );
      dispatch({
        type: GET_ALL_MASTER_TOPUP_AGENCIES_OF_ADMIN,
        payload: {
          agencies: data?.agenciesList,
          total: data?.agenciesList?.length,
        },
      });
    } catch (error) {
      Toast("error", error.response.data.message || error.response.data.error);
    }
  };
export const handleBlockUnblockSwitch = (masterId) => async (dispatch) => {
  try {
    const { data } = await axios.put(`/admin/blockUnblock/${masterId}`);
    Toast("success", data.message);
    if (data && data?.admin) {
      dispatch({ type: BLOCK_UNBLOCK_MASTER_ADMIN, payload: data.admin });
    }
  } catch (error) {
    Toast("error", error.message);
  }
};
