import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { baseURL } from "../../util/Config";
import HostTable from "./HostList";
import DiamondHistoryTable from "../Master/DiamondHistoryTable";
import {
  getDetailsSubAdmin,
  getAllAgencyHostsForAdmins,
} from "../../store/agency/action";
import male from "../../assets/images/male.png";
import SalarySheet from "../admin/SalarySheet";
import Pagination from "../../pages/Pagination";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";
import Spinner from "../../pages/Spinner";

const AgencyDetail = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = queryParams.get("id");
  const [status, setStatus] = useState("sent");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageTopup, setRowsPerPageTopup] = useState(100);

  const [search, setSearch] = useState("ALL");
  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const [activeTab, setActiveTab] = useState("Hosts");
  const [sentPage, setSentPage] = useState(1);
  const [receivedPage, setReceivedPage] = useState(1);
  const [sentRowsPerPage, setSentRowsPerPage] = useState(10);
  const [receivedRowsPerPage, setReceivedRowsPerPage] = useState(10);

  const { agency, hosts, total, totalRecord } = useSelector(
    (state) => state?.agency
  );

  useEffect(() => {
    const rowsPerPage =
      status === "sent" ? sentRowsPerPage : receivedRowsPerPage;
    const currentPage = status === "sent" ? sentPage : receivedPage;

    const fetchDetails = async () => {
      await props.getDetailsSubAdmin(
        params,
        currentPage,
        rowsPerPage,
        search,
        sDate,
        eDate,
        status // Include status as a dependency in getDetailsSubAdmin call
      );
      setLoading(false);
    };
    console.log("🚀 ~ fetchDetails ~ status:", status);
    console.log("🚀 ~ fetchDetails ~ status:", status);

    fetchDetails();
  }, [
    params,
    status,
    sentPage,
    receivedPage,
    sentRowsPerPage,
    receivedRowsPerPage,
    search,
    sDate,
    eDate,
  ]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
    setData(hosts);
  }, [date, hosts]);

  // useEffect(() => {
  //   setData(hosts);
  // }, [hosts]);

  // useEffect(() => {
  //   if (agency?.isHostAgency) {
  //     // setActiveTab("Hosts");
  //     props.getAllAgencyHostsForAdmins(
  //       params,
  //       activePage,
  //       rowsPerPage,
  //       search,
  //       sDate,
  //       eDate
  //     );
  //   }
  //   // else if (agency?.isTopUpAgency) {
  //   //   setActiveTab("SendCoins");
  //   // }
  // }, [agency, params, activePage, rowsPerPage, search, sDate, eDate]);

  useEffect(() => {
    if (activeTab === "Hosts" && agency?.isHostAgency) {
      props.getAllAgencyHostsForAdmins(
        params,
        activePage,
        rowsPerPage,
        search,
        sDate,
        eDate
      );
    }
  }, [
    activeTab,
    activePage,
    rowsPerPage,
    search,
    sDate,
    eDate,
    agency,
    params,
  ]);
  const initialTabSet = useRef(false);

  useEffect(() => {
    if (!initialTabSet.current && agency?.isTopUpAgency) {
      setActiveTab("SendCoins");
      setStatus("sent");
      initialTabSet.current = true;
    }else if(!initialTabSet.current && agency?.isTopUpAgency &&agency.isHostAgency){
      setActiveTab("Hosts");

    }
  }, [agency]);
  // useEffect(() => {
  //   if (!initialTabSet.current) {
  //     if (agency?.isHostAgency && agency?.isTopUpAgency) {
  //       setActiveTab("Hosts");
  //       setStatus("sent"); // Assuming you want to default to "sent" status for Hosts
  //     } else if (agency?.isHostAgency) {
  //       setActiveTab("Hosts");
  //     } else if (agency?.isTopUpAgency) {
  //       setActiveTab("SendCoins");
  //       setStatus("sent"); // Assuming you want to default to "sent" status for SendCoins
  //     }
  //     initialTabSet.current = true;
  //   }
  // }, [agency]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setActivePage(1);
    if (tab === "SendCoins") {
      setStatus("sent");
    } else if (tab === "ReceivedCoins") {
      setStatus("received");
    }
  };

  const handlePageChange = (pageNumber) => {
    if (activeTab === "SendCoins") {
      setSentPage(pageNumber);
    } else if (activeTab === "ReceivedCoins") {
      setReceivedPage(pageNumber);
    } else {
      setActivePage(pageNumber);
    }
  };

  const handleRowsPerPage = (value) => {
    if (activeTab === "SendCoins") {
      setSentRowsPerPage(Number(value));
      setSentPage(1);
    } else if (activeTab === "ReceivedCoins") {
      setReceivedRowsPerPage(Number(value));
      setReceivedPage(1);
    } else {
      setRowsPerPage(Number(value));
      setActivePage(1);
    }
  };

  const link = location.pathname.startsWith("/admin")
    ? "/admin"
    : "/superAdmin";

  if (loading) {
    return <Spinner />
  }

  // const sentCoinsCount =
  //   agency?.topUpAgencyDetails?.diamondsHistory?.filter(
  //     (record) => record.status === "sent"
  //   ).length || 0;

  // const receivedCoinsCount =
  //   agency?.topUpAgencyDetails?.diamondsHistory?.filter(
  //     (record) => record.status === "received"
  //   ).length || 0;

  // const filteredDiamondHistoryData =
  //   agency?.topUpAgencyDetails?.diamondsHistory?.filter(
  //     (record) => record.status === status
  //   ) || [];

  const diamondHistoryData = agency?.topUpAgencyDetails?.diamondsHistory || [];
  const paginatedData = diamondHistoryData.filter(
    (item) => item.status === status
  );
  console.log("🚀 ~ AgencyDetail ~ paginatedData:", paginatedData);
  const getAlLHosts = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    props.getDetailsSubAdmin(
      params,
      activePage,
      status === "sent" ? sentRowsPerPage : receivedRowsPerPage,
      search,
      sDate,
      eDate,
      status
    );
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (status === "sent") {
      setSentPage(1);
    } else {
      setReceivedPage(1);
    }
  };
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last"></div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`${link}/dashboard`} className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`${link}/agency`} className="text-danger">
                    Agency
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="profile-cover"></div>
          <div className="profile-header">
            <div className="profile-img">
              <img
                src={agency?.image ? `${baseURL}${agency?.image}` : male}
                alt=""
              />
            </div>
            <div className="profile-name">
              <h3>{agency?.name}</h3>
            </div>
            <div className="profile-header-menu">
              <ul className="list-unstyled" id="manageAgencyHosts">
                {agency?.isHostAgency && (
                  <>
                    <li>
                      <a
                        href={() => false}
                        className={`pointer-cursor ${
                          activeTab === "Hosts" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("Hosts")}
                      >
                        Hosts ({total || 0})
                      </a>
                    </li>
                    <li>
                      <a
                        href={() => false}
                        className={`pointer-cursor ${
                          activeTab === "SalarySheet" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("SalarySheet")}
                      >
                        SalarySheet
                      </a>
                    </li>
                  </>
                )}
                {agency?.isTopUpAgency && (
                  <>
                    <li>
                      <a
                        href={() => false}
                        className={`pointer-cursor ${
                          activeTab === "SendCoins" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("SendCoins")}
                      >
                        Send Coins
                        {/* ({sentCoinsCount}) */}
                      </a>
                    </li>
                    <li>
                      <a
                        href={() => false}
                        className={`pointer-cursor ${
                          activeTab === "ReceivedCoins" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("ReceivedCoins")}
                      >
                        Received Coins
                        {/* ({receivedCoinsCount}) */}
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card card-bg">
            <div className="card-body">
              {activeTab === "Hosts" ? (
                <div>
                  <div className="row post-comments d-flex flex-wrap justify-content-between">
                    <HostTable
                      data={hosts}
                      total={total}
                      params={params}
                      sDate={sDate}
                      eDate={eDate}
                      setActivePage={setActivePage}
                      setSearch={setSearch}
                      date={date}
                      activePage={activePage}
                      rowsPerPage={rowsPerPage}
                      setDate={setDate}
                      setsDate={setsDate}
                      seteDate={seteDate}
                      setRowsPerPage={setRowsPerPage}
                      getAllAgencyHostsForAdmins={getAllAgencyHostsForAdmins}
                    />
                  </div>
                </div>
              ) : activeTab === "SalarySheet" ? (
                <SalarySheet agencyId={agency._id} />
              ) : activeTab === "ReceivedCoins" || activeTab === "SendCoins" ? (
                <>
                  <div className="row">
                    <div className="col">
                      <div className="card" id="card"></div>
                      <div className="card-header pb-0">
                        <div className="row my-3">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                            <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                              <button
                                className="btn btn-info"
                                style={{ marginRight: 5 }}
                                onClick={getAlLHosts}
                              >
                                All
                              </button>
                              <button
                                className="collapsed btn btn-info ml-5"
                                value="check"
                                data-toggle="collapse"
                                data-target="#datePicker"
                              >
                                Analytics
                                <ExpandMoreIcon />
                              </button>
                              <p style={{ paddingLeft: 10 }} className="my-2 ">
                                {sDate !== "ALL" && sDate + " to " + eDate}
                              </p>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right d-flex align-items-center justify-content-end">
                            <form action="">
                              <div className="input-group mb-3 border rounded-pill">
                                <div className="input-group-prepend border-0">
                                  <div
                                    id="button-addon4"
                                    className="btn text-danger"
                                  >
                                    <i className="fas fa-search mt-2"></i>
                                  </div>
                                </div>
                                <input
                                  type="search"
                                  id="searchBar"
                                  autoComplete="off"
                                  placeholder="What're you searching for?"
                                  aria-describedby="button-addon4"
                                  className="form-control bg-none border-0 rounded-pill searchBar"
                                  onChange={handleSearchChange}
                                />
                              </div>
                            </form>
                          </div>
                          <div
                            id="datePicker"
                            className="collapse mt-5 pt-5"
                            aria-expanded="false"
                          >
                            <div className="container table-responsive">
                              <div key={JSON.stringify(date)}>
                                <DateRangePicker
                                  onChange={(item) => {
                                    setDate([item.selection]);
                                    const dayStart = dayjs(
                                      item.selection.startDate
                                    ).format("MM/DD/YYYY");
                                    const dayEnd = dayjs(
                                      item.selection.endDate
                                    ).format("MM/DD/YYYY");
                                    setActivePage(1);
                                    setsDate(dayStart);
                                    seteDate(dayEnd);

                                    // props.getDetailsSubAdmin(
                                    //   null,
                                    //   activePage,
                                    //   rowsPerPage,
                                    //   search,
                                    //   sDate,
                                    //   eDate
                                    // );
                                  }}
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  ranges={date}
                                  direction="horizontal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <DiamondHistoryTable
                        data={paginatedData}
                        status={status}
                        activePage={status === "sent" ? sentPage : receivedPage}
                        rowsPerPage={
                          status === "sent"
                            ? sentRowsPerPage
                            : receivedRowsPerPage
                        }
                      />

                      <Pagination
                        activePage={status === "sent" ? sentPage : receivedPage}
                        itemsCountPerPage={
                          status === "sent"
                            ? sentRowsPerPage
                            : receivedRowsPerPage
                        }
                        userTotal={totalRecord}
                        onChange={handlePageChange}
                        handleRowsPerPage={handleRowsPerPage}
                        currentPage={
                          status === "sent" ? sentPage : receivedPage
                        }
                        rowsPerPage={
                          status === "sent"
                            ? sentRowsPerPage
                            : receivedRowsPerPage
                        }
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center">Nothing to Show!!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getDetailsSubAdmin,
  getAllAgencyHostsForAdmins,
})(AgencyDetail);
