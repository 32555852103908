import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";

const SuperAdminAuthRouter = ({
  component: Component,
  isSuperAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isSuperAdmin === true ? (
        <Redirect to="/superAdmin" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  isSuperAdmin: state.superAdmin.isSuperAdmin,
});

export default connect(mapStateToProps)(SuperAdminAuthRouter);
