import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Navbar from "../component/navbar/SuperAdminNavbar.js";
import Topnav from "../component/navbar/SuperAdminTopNav.js";
import BannerTable from "../component/table/Banner.js";
import CoinPlanTable from "../component/table/CoinPlan.js";
import PurchaseCoinPlanHistoryTable from "../component/table/PurchaseCoinPlanHistory.js";
import VIPPlanTable from "../component/table/VIPPlan.js";
import PurchaseVIPPlanTable from "../component/table/PurchaseVipPlanHistory.js";
import GiftCategoryTable from "../component/table/GiftCategory.js";
import GiftTable from "../component/table/Gift.js";
import SongTable from "../component/table/Song.js";
import SongDialog from "../component/dialog/Song.js";
import GiftDialog from "../component/dialog/Gift/Add.js";
import HashtagTable from "../component/table/Hashtag.js";
import LevelTable from "../component/table/Level.js";
import UserTable from "../component/table/User.js";
import PostTable from "../component/table/Post.js";
import VideoTable from "../component/table/Video.js";
import UserDetail from "./UserDetail.js";
import UserHistory from "./UserHistory.js";
import PostDetail from "./PostDetail.js";
import VideoDetail from "./VideoDetail.js";
import Dashboard from "./Dashboard.js";
import Setting from "./Settings.js";
import ThemeTable from "../component/table/Theme.js";
import Advertisement from "../component/table/Advertisement.js";
import PendingComplainTable from "../component/table/PendingComplain.js";
import SolvedComplainTable from "../component/table/SolvedComplain.js";
import PendingRedeemTable from "../component/table/PendingRedeem.js";
import AcceptedRedeemTable from "../component/table/AcceptedRedeem.js";
import DeclineRedeemTable from "../component/table/DeclineRedeem.js";
import ReportedUserTable from "../component/table/ReportedUser.js";
import StickerTable from "../component/table/Sticker.js";
import FakeUser from "../component/table/FakeUser.js";
import FakeUserPage from "../component/dialog/FakeUserPage.js";
import FakePost from "../component/table/FakePost.js";
import FakeVideo from "../component/table/FakeVideo.js";
import FakePostPage from "../component/dialog/FakePostPage.js";
import FakeVideoPage from "../component/dialog/FakeVideoPage.js";
import TopSellerTable from "../component/table/TopSeller.js";
import MasterTopUpAgencyTable from "../component/superAdmin/MasterTopUpAgencyTable.js";
import AgencyTableTopUpHosting from "../component/superAdmin/AgencyTable.js";
import MasterAdminList from "../component/superAdmin/MasterAdminList.js";
import SubAdminTable from "../component/table/SubAdmin.js";
import HostTable from "../component/table/Host.js";
import TopSellerDetails from "../component/TopupReseller/TopSellerDetails.js";
import AgencyRegistration from "../component/superAdmin/AgenciesRegistration.js";
import MasterAdminRegistration from "../component/superAdmin/MasterRegistration.js";
import MasterDetails from "../component/superAdmin/MasterDetails.js";
import CoinManage from "../component/superAdmin/CoinManagement.js";
//loader
import Spinner from "./Spinner.js";
import Profile from "./Profile.js";
import FakeComment from "../component/table/FakeComment.js";
import TopSellerDiamondHistory from "../component/table/TopSellerDiamondHistory.js";
import DiamondHistory from "../component/superAdmin/DiamondHistory.js";
import Permission from "../component/superAdmin/Permission.js";
import AllAgenciesTable from "../component/superAdmin/AllAgenciesTable.js";
import AllMasterTopupAgencyTable from "../component/superAdmin/AllMasterTopupAgency.js";
import AgencyDetail from "../component/Agency/AgencyDetails.js";
import SalarySheet from "../component/admin/SalarySheet.js";
import MasterTopUpDiamondHistory from "../component/MasterAgency/DiamondHistory.js";
import FrameTable from "../component/table/FrameTable.js";

const SuperAdmin = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/superAdmin") {
      history.push("/superAdmin/dashboard");
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <div class="page-container">
        <Navbar />
        <div class="page-content">
          <Topnav />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location.path}/dashboard`}
                exact
                component={Dashboard}
              />
              <Route
                path={`${location.path}/profile`}
                exact
                component={Profile}
              />
              <Route
                path={`${location.path}/banner`}
                exact
                component={BannerTable}
              />
              <Route
                path={`${location.path}/coinplan`}
                exact
                component={CoinPlanTable}
              />
              <Route
                path={`${location.path}/coinplan/history`}
                exact
                component={PurchaseCoinPlanHistoryTable}
              />
              <Route
                path={`${location.path}/vipplan`}
                exact
                component={VIPPlanTable}
              />
              <Route
                path={`${location.path}/vipplan/history`}
                exact
                component={PurchaseVIPPlanTable}
              />
              <Route
                path={`${location.path}/topSeller/diamonds/history`}
                exact
                component={TopSellerDiamondHistory}
              />
              <Route
                path={`${location.path}/giftCategory`}
                exact
                component={GiftCategoryTable}
              />
              <Route
                path={`${location.path}/theme`}
                exact
                component={ThemeTable}
              />
              <Route
                path={`${location.path}/giftCategory/gift`}
                exact
                component={GiftTable}
              />
              <Route
                path={`${location.path}/giftCategory/gift/dialog`}
                exact
                component={GiftDialog}
              />
              <Route
                path={`${location.path}/gift`}
                exact
                component={GiftTable}
              />
              <Route
                path={`${location.path}/gift/dialog`}
                exact
                component={GiftDialog}
              />
              <Route
                path={`${location.path}/song`}
                exact
                component={SongTable}
              />
              <Route
                path={`${location.path}/song/dialog`}
                exact
                component={SongDialog}
              />
              <Route
                path={`${location.path}/hashtag`}
                exact
                component={HashtagTable}
              />
              <Route
                path={`${location.path}/level`}
                exact
                component={LevelTable}
              />
              <Route
                path={`${location.path}/frame`}
                exact
                component={FrameTable}
              />
              <Route
                path={`${location.path}/user`}
                exact
                component={UserTable}
              />
              <Route
                path={`${location.path}/top-seller`}
                exact
                component={TopSellerTable}
              />
              <Route
                path={`${location.path}/top-seller/detail`}
                exact
                component={TopSellerDetails}
              />
              <Route
                path={`${location.path}/masterAdmin`}
                exact
                component={MasterAdminList}
              />
              <Route
                path={`${location.path}/masterAdmin/detail`}
                exact
                component={MasterDetails}
              />
              <Route
                path={`${location.path}/masterAdmin/permission`}
                exact
                component={Permission}
              />
              <Route
                path={`${location.path}/agency`}
                exact
                component={AgencyTableTopUpHosting}
              />
              <Route
                path={`${location.path}/agency/salarySheet/:agencyId`}
                exact
                component={SalarySheet}
              />
              <Route
                path={`${location.path}/agency/detail`}
                exact
                component={AgencyDetail}
              />
              <Route
                path={`${location.path}/allagency`}
                exact
                component={AllAgenciesTable}
              />
              <Route
                path={`${location.path}/masterAgency`}
                exact
                component={MasterTopUpAgencyTable}
              />
              <Route
                path={`${location.path}/masterAgency/history/:masterTopUpAgencyId/:masterTopupAgencyName`}
                exact
                component={MasterTopUpDiamondHistory}
              />
              <Route
                path={`${location.path}/allmasterAgency`}
                exact
                component={AllMasterTopupAgencyTable}
              />
              <Route
                path={`${location.path}/agency/create`}
                exact
                component={AgencyRegistration}
              />
              <Route
                path={`${location.path}/masterAdmin/create`}
                exact
                component={MasterAdminRegistration}
              />
              <Route
                path={`${location.path}/coins`}
                exact
                component={CoinManage}
              />
              <Route
                path={`${location.path}/history/:status`}
                exact
                component={DiamondHistory}
              />
              <Route
                path={`${location.path}/sub-admin`}
                exact
                component={SubAdminTable}
              />
              <Route
                path={`${location.path}/host`}
                exact
                component={HostTable}
              />
              <Route
                path={`${location.path}/fakeUser`}
                exact
                component={FakeUser}
              />
              <Route
                path={`${location.path}/fake/fakeUserdialog`}
                exact
                component={FakeUserPage}
              />
              <Route
                path={`${location.path}/user/detail`}
                exact
                component={UserDetail}
              />
              <Route
                path={`${location.path}/user/history`}
                exact
                component={UserHistory}
              />

              <Route
                path={`${location.path}/post`}
                exact
                component={PostTable}
              />
              <Route
                path={`${location.path}/post/dialog`}
                exact
                component={FakePostPage}
              />
              <Route
                path={`${location.path}/post/detail`}
                exact
                component={PostDetail}
              />
              <Route
                path={`${location.path}/post/fake`}
                exact
                component={FakePost}
              />
              <Route
                path={`${location.path}/video`}
                exact
                component={VideoTable}
              />
              <Route
                path={`${location.path}/video/fake`}
                exact
                component={FakeVideo}
              />
              <Route
                path={`${location.path}/setting`}
                exact
                component={Setting}
              />
              <Route
                path={`${location.path}/video/detail`}
                exact
                component={VideoDetail}
              />
              <Route
                path={`${location.path}/reportedUser`}
                exact
                component={ReportedUserTable}
              />
              <Route
                path={`${location.path}/advertisement`}
                exact
                component={Advertisement}
              />
              <Route
                path={`${location.path}/pendingComplain`}
                exact
                component={PendingComplainTable}
              />
              <Route
                path={`${location.path}/solvedComplain`}
                exact
                component={SolvedComplainTable}
              />
              <Route
                path={`${location.path}/pendingRedeem`}
                exact
                component={PendingRedeemTable}
              />
              <Route
                path={`${location.path}/acceptedRedeem`}
                exact
                component={AcceptedRedeemTable}
              />
              <Route
                path={`${location.path}/declineRedeem`}
                exact
                component={DeclineRedeemTable}
              />
              <Route
                path={`${location.path}/sticker`}
                exact
                component={StickerTable}
              />
              <Route
                path={`${location.path}/post/dialog`}
                exact
                component={FakePostPage}
              />
              <Route
                path={`${location.path}/post/fake`}
                exact
                component={FakePost}
              />

              <Route
                path={`${location.path}/video/fake`}
                exact
                component={FakeVideo}
              />
              <Route
                path={`${location.path}/video/dialog`}
                exact
                component={FakeVideoPage}
              />
              <Route
                path={`${location.path}/comment`}
                exact
                component={FakeComment}
              />
            </Switch>
            <Spinner />
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdmin;
